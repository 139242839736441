import React, { useState, useEffect } from "react";
import {
  TrashIcon,
  PencilIcon,
  PlusIcon,
  ArrowPathIcon,
  ClockIcon,
  CheckIcon,
  XMarkIcon,
  BellAlertIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import CustomerService from "../../services/customer.service";
import { format } from "date-fns";
import validator from "validator";
import { Link } from "react-router-dom";
import OrderService from "../../services/order.service";
import OrderTotalCalculator from "../../components/OrderTotalCalculator";
import DropdownComponent from "../../components/DropdownComponent";
import ConfirmModalComponent from "../../components/ConfirmModal";
import CustomDropDown from "../../components/CustomDropDown";
import pickupService from "../../services/pickup.service";
import ConfirmPickupModalComponent from "../../components/ConfirmPickupModal";
import AddUserModal from "../../components/AddUserModal";
import authService from "../../services/auth.service";
import orderService from "../../services/order.service";
import AddLocationModal from "../../components/AddLocationModal";

function Locations() {
  const [openNewCModal, setOpenNewCModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [cancelRequest, setCancelRequest] = useState(false);
  const [confirmPickup, setConfirmPickup] = useState({});

  const [deleteLocation, setDeleteLocation] = useState();
  const [editUser, setEditUser] = useState();
  const [locationsList, setLocationsList] = useState([]);
  const [delCount, setDelCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [error, setError] = useState();
  const [listError, setListError] = useState();

  const [form, setForm] = useState({});

  const auth = useSelector((state) => state.auth);

  const getLocations = () => {
    setListLoading(true);
    setListError(false);
    orderService
      .getLocations()
      .then((resp) => {
        setLocationsList(resp.locations);
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
        setListError("Something went wrong.");
        console.log(err);
      });
  };
  const handleDeleteLocation = (id) => {
    setListLoading(true);
    setListError(false);
    setOpenConfirmModal(false);
    orderService
      .deleteLocation(id)
      .then((resp) => {
        getLocations();
        setDeleteLocation();
      })
      .catch((err) => {
        setListLoading(false);
        setListError("Something went wrong.");
        console.log(err);
      });
  };
  useEffect(() => {
    getLocations();
  }, []);
  return (
    <div className="h-full">
      <div className="flex flex-col sm:flex-row sm:items-center gap-4 mb-6">
        <h1 className="text-2xl font-semibold flex-1">Locations</h1>
        <div className="flex flex-wrap items-center gap-3">
          {delCount > 0 && (
            <button className="inline-flex items-center gap-2 px-4 py-2 text-red-600 hover:text-red-700 border border-red-200 hover:border-red-300 rounded-lg transition-colors duration-200">
              <TrashIcon className="h-4 w-4" />
              <span>Delete {delCount} selected</span>
            </button>
          )}
          <button
            onClick={() => {
              setOpenLocationModal(true);
              setEditUser();
            }}
            className="inline-flex items-center gap-2 px-4 py-2 bg-black text-white font-medium rounded-lg hover:bg-gray-800 transition-colors duration-200"
          >
            <PlusIcon className="h-4 w-4" />
            <span>Create Location</span>
          </button>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
        <div className="w-full overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 text-xs font-medium text-gray-500 uppercase tracking-wider">
                <th className="py-3 px-6 text-left">Name</th>
                <th className="py-3 px-6 text-left">Action</th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-100">
              {listLoading ? (
                [...Array(3)].map((_, i) => (
                  <tr key={i}>
                    <td colSpan={2} className="py-3 px-6">
                      <div className="animate-pulse flex items-center space-x-4">
                        <div className="h-4 w-4 bg-gray-200 rounded"></div>
                        <div className="flex-1 space-y-4">
                          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : listError ? (
                <tr>
                  <td colSpan={2} className="py-8 text-center text-gray-500">
                    <div className="flex flex-col items-center gap-2">
                      <ExclamationCircleIcon className="h-5 w-5 text-gray-400" />
                      <p>{listError}</p>
                      <button
                        onClick={() => getLocations()}
                        className="text-black hover:text-gray-600 font-medium"
                      >
                        Try again
                      </button>
                    </div>
                  </td>
                </tr>
              ) : locationsList.length === 0 ? (
                <tr>
                  <td colSpan={2} className="py-8 text-center text-gray-500">
                    <p>No locations found</p>
                  </td>
                </tr>
              ) : (
                locationsList.map((location, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="py-3 px-6">
                      <div className="font-medium text-gray-900">{location.name}</div>
                    </td>
                    <td className="py-3 px-6">
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => {
                            setDeleteLocation(location);
                            setOpenConfirmModal(true);
                          }}
                          className="inline-flex items-center gap-2 px-3 py-1.5 text-sm text-red-600 hover:text-red-900 hover:bg-red-50 rounded-lg"
                        >
                          <XMarkIcon className="h-4 w-4" />
                          <span>Delete</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className="border-t border-gray-100 px-6 py-4">
          <small className="text-gray-500">
            Showing {locationsList.length} results
          </small>
        </div>
      </div>

      {openConfirmModal && (
        <div
          className={`relative z-10`}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <BellAlertIcon className="w-6 h-6" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3
                        className="text-lg font-medium leading-6 text-gray-900"
                        id="modal-title"
                      >
                        Confirm action?
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete {deleteLocation.name}?
                          This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    onClick={() => handleDeleteLocation(deleteLocation.id)}
                    disabled={loading}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {!loading ? (
                      "Continue"
                    ) : (
                      <ArrowPathIcon className="h-4 w-4 mx-auto animate-spin" />
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={() => setOpenConfirmModal(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {openLocationModal && (
        <AddLocationModal
          close={() => setOpenLocationModal(false)}
          getLocations={getLocations}
          editUser={editUser}
        />
      )}
    </div>
  );
}
export default Locations;

import React, { forwardRef, useState, useEffect, useRef } from "react";
import {
  TrashIcon,
  UserCircleIcon,
  PlusIcon,
  ShoppingCartIcon,
  MinusIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckCircleIcon,
  CalendarDaysIcon,
  XMarkIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import CustomerService from "../../services/customer.service";
import validator from "validator";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { Link, useNavigate, useParams } from "react-router-dom";
import OrderService from "../../services/order.service";
import OrderTotalCalculator from "../../components/OrderTotalCalculator";
import BackButton from "../../components/BackButton";
import AddressPicker from "../../components/AddressPicker";

function EditOrder() {
  let { order } = useParams();
  const [dropFee, setDropFee] = useState(0);
  const [returnFee, setReturnFee] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(0);
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const [searchCustomers, setSearchCustomers] = useState([]);
  const [collectionData, setCollectionData] = useState({
    dropType: "walk-in",
    returnType: "walk-in",
    dropDate: new Date(),
    returnDate: new Date(Date.now() + 3600 * 1000 * 72),
  });
  const [servicesList, setServicesList] = useState([]);
  const [orderLoading, setOrderLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [service, setService] = useState("");
  const [customer, setCustomer] = useState();
  const [locationsList, setLocationsList] = useState([]);
  const [location, setLocation] = useState("");
  const [orderDetails, setOrderDetails] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [addressModal, setAddressModal] = useState("");
  const [selectedAddresses, setSelectedAddresses] = useState({});

  const getLocations = () => {
    OrderService.getLocations()
      .then((resp) => {
        setLocationsList(resp.locations);
      })
      .catch((err) => { });
  };
  const navigate = useNavigate();

  const ref = useRef(null);

  const [pay, setPay] = useState("");

  const handleUpdateOrder = (confirm) => {
    setLoading(true);
    let servicePayload = {};
    orderList.map(
      (order) =>
        (servicePayload = { ...servicePayload, [order.code]: order.qty })
    );
    const request = {
      drop: {
        fee: dropFee * 100,
        type: collectionData.dropType, // delivery here means we go pick it up
        date: collectionData.dropDate.toISOString().split("T")[0], // ISO 8601 format : YYYY-MM-DD
        address: selectedAddresses.drop,
      },
      return: {
        fee: returnFee * 100,
        type: collectionData.returnType, // delivery here means we go pick it up
        date: collectionData.returnDate.toISOString().split("T")[0], // ISO 8601 format : YYYY-MM-DD
        address: selectedAddresses.return,
      },
      id: order,
      customerId: customer.id,
      services: servicePayload,
      serviceLocationId: location,
      paymentMethod: !pay ? "" : pay,
      discount,
      confirm,
    };
    console.log(request);
    OrderService.updateOrder(request, confirm)
      .then((resp) => {
        setLoading(false);
        navigate("/dashboard/orders");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleClickOutsideSearch = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenSuggestions(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideSearch, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideSearch, true);
    };
  }, []);
  const handleAddOrder = () => {
    if (service !== "") {
      if (orderList.filter((s) => s.code === service).length > 0) {
        const nextCounters = orderList.map((c, i) => {
          if (c.code === service) {
            // Increment the clicked counter
            c.qty = c.qty + 1;
            return c;
          } else {
            // The rest haven't changed
            return c;
          }
        });
        setOrderList(nextCounters);
        return;
      }
      let selectService = servicesList.filter((s) => s.code === service);
      selectService[0].qty = 1;
      setOrderList((current) => [...current, selectService[0]]);
    }
  };
  const handleQtyChange = (index, newQty) => {
    const nextCounters = orderList.map((c, i) => {
      if (i === index && newQty > 0) {
        c.qty = newQty;
      }
      return c;
    });
    setOrderList(nextCounters);
  };
  const removeService = (index) => {
    const remainingServices = orderList.filter(
      (file) => file !== orderList[index]
    );
    setOrderList(remainingServices);
  };
  const getServices = () => {
    OrderService.getServices()
      .then((resp) => {
        setServicesList(resp.services);
      })
      .catch((err) => { });
  };
  const getOrder = () => {
    setOrderLoading(true);
    OrderService.getOrder(order)
      .then((resp) => {
        setOrderDetails(resp);
        setCustomer(resp.customer);
        let orderLis = [];
        Object.keys(resp.services).forEach(function (key) {
          orderLis.push({ code: key, qty: resp.services[key] });
        });
        setSelectedAddresses({
          drop: resp.drop.address,
          return: resp.return.address,
        });
        setLocation(resp.serviceLocation.id);
        setOrderList(orderLis);
        setCollectionData({
          dropDate: new Date(resp.drop.date),
          returnDate: new Date(resp.return.date),
          dropType: resp.drop.type,
          returnType: resp.return.type,
        });
        setPay(resp.paymentMethod);
        setDropFee(resp.drop.fee / 100);
        setReturnFee(resp.return.fee / 100);
        setDiscount(resp.discount);
        setOrderLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setOrderLoading(false);
      });
  };
  const findCustomer = (q) => {
    setOpenSuggestions(true);
    CustomerService.getCustomers(q, 10, 1)
      .then((resp) => {
        setSearchCustomers(resp.customers);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    getServices();
    getOrder();
    getLocations();
  }, []);
  return (
    <>
      {orderLoading || !orderDetails ? (
        <div className="flex items-center justify-center h-full">
          <ArrowPathIcon className="h-10 w-10 mx-auto animate-spin" />
        </div>
      ) : (
        <div className="h-full">
          {/* Header */}
          <div className="flex flex-col sm:flex-row sm:items-center gap-4 mb-6">
            <BackButton />
            <div>
              <h1 className="text-2xl font-semibold">Edit Order #{orderDetails.number}</h1>
              <p className="mt-1 text-sm text-gray-500">
                {format(new Date(orderDetails.createdAt), "dd MMMM yyyy 'at' HH:mm")}
              </p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row gap-6">
            {/* Main Content */}
            <div className="flex-1 space-y-6">
              {/* Services Section */}
              <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-lg font-medium">Services</h2>
                  <span className={`text-xs font-medium px-2 py-1 rounded-full ${orderDetails.status === "draft"
                    ? "text-gray-600 bg-gray-100"
                    : orderDetails.status === "processing"
                      ? "text-yellow-600 bg-yellow-100"
                      : "text-green-600 bg-green-100"
                    }`}>
                    {orderDetails.status}
                  </span>
                </div>

                <div className="divide-y divide-gray-100">
                  {orderList.map((order, index) => (
                    <div key={index} className="group py-4">
                      <div className="flex flex-col sm:flex-row sm:items-center gap-3">
                        <div className="flex-1">
                          <span className="font-medium text-gray-900">
                            {servicesList.filter((service) => service.code === order.code)[0]?.name}
                          </span>
                        </div>
                        {orderDetails.status === "draft" ? (
                          <div className="flex items-center gap-4">
                            <div className="flex items-center gap-2">
                              <button
                                onClick={() => handleQtyChange(index, order.qty - 1)}
                                className="p-2 rounded-lg hover:bg-gray-100 text-gray-600"
                              >
                                <MinusIcon className="h-4 w-4" />
                              </button>
                              <input
                                type="number"
                                className="w-16 text-center border border-gray-200 rounded-lg focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                                min={1}
                                onChange={(e) => handleQtyChange(index, e.target.valueAsNumber)}
                                value={order.qty}
                              />
                              <button
                                onClick={() => handleQtyChange(index, order.qty + 1)}
                                className="p-2 rounded-lg hover:bg-gray-100 text-gray-600"
                              >
                                <PlusIcon className="h-4 w-4" />
                              </button>
                            </div>
                            <span className="font-medium whitespace-nowrap">
                              ₦{((order.qty * servicesList.filter((service) => service.code === order.code)[0]?.cost) / 100).toLocaleString()}
                            </span>
                            <button
                              className="text-red-600 opacity-0 group-hover:opacity-100 transition-opacity"
                              onClick={() => removeService(index)}
                            >
                              Remove
                            </button>
                          </div>
                        ) : (
                          <div className="flex items-center gap-4">
                            <span className="text-gray-600">x{order.qty}</span>
                            <span className="font-medium whitespace-nowrap">
                              ₦{((order.qty * servicesList.filter((service) => service.code === order.code)[0]?.cost) / 100).toLocaleString()}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                {orderDetails.status === "draft" && (
                  <div className="mt-4 flex gap-3">
                    <select
                      value={service}
                      onChange={(e) => setService(e.target.value)}
                      className="flex-1 rounded-lg h-10 pl-2 w-full border border-gray-200 focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                    >
                      <option value="">Choose a service</option>
                      {servicesList.map((service) => (
                        <option key={service.code} value={service.code}>
                          {service.name}
                        </option>
                      ))}
                    </select>
                    <button
                      className="flex items-center gap-2 bg-black hover:bg-gray-800 text-white px-4 py-2 rounded-lg transition-colors duration-200"
                      onClick={handleAddOrder}
                    >
                      <ShoppingCartIcon className="h-4 w-4" />
                    </button>
                  </div>
                )}
              </div>

              {/* Collection Details */}
              <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                <div className="grid md:grid-cols-2 gap-6">
                  {/* Drop Off */}
                  <div className="space-y-4">
                    <h2 className="text-lg font-medium">Drop Off</h2>
                    <select
                      value={collectionData.dropType}
                      onChange={(e) => {
                        setCollectionData({
                          ...collectionData,
                          dropType: e.target.value,
                        });
                      }}
                      className="w-full rounded-lg h-10 pl-2 border border-gray-200 focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                    >
                      <option value="walk-in">Walk In</option>
                      <option value="delivery">Pickup</option>
                    </select>

                    <div className="relative">
                      <DatePicker
                        selected={collectionData.dropDate}
                        onChange={(date) => {
                          let endD = new Date();
                          endD.setFullYear(date.getFullYear());
                          endD.setMonth(date.getMonth());
                          endD.setDate(date.getDate() + 5);

                          setCollectionData({
                            ...collectionData,
                            dropDate: date,
                            returnDate:
                              date > collectionData.returnDate
                                ? endD
                                : collectionData.returnDate,
                          });
                        }}
                        selectsStart
                        startDate={collectionData.dropDate}
                        endDate={collectionData.returnDate}
                        minDate={new Date()}
                        nextMonthButtonLabel=">"
                        previousMonthButtonLabel="<"
                        popperClassName="react-datepicker-left"
                        customInput={<ButtonInput />}
                        renderCustomHeader={({
                          date,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div className="flex items-center justify-between px-4 py-2">
                            <span className="text-lg text-gray-900">
                              {format(date, "MMMM yyyy")}
                            </span>

                            <div className="flex gap-1">
                              <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                className={`
                                  p-1 rounded hover:bg-gray-100
                                  ${prevMonthButtonDisabled && "opacity-50 cursor-not-allowed"}
                                `}
                              >
                                <ChevronLeftIcon className="h-5 w-5" />
                              </button>

                              <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                className={`
                                  p-1 rounded hover:bg-gray-100
                                  ${nextMonthButtonDisabled && "opacity-50 cursor-not-allowed"}
                                `}
                              >
                                <ChevronRightIcon className="h-5 w-5" />
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    </div>

                    {collectionData.dropType === "delivery" &&
                      (selectedAddresses.drop ? (
                        <div className="p-4 bg-gray-50 rounded-lg">
                          <div className="font-medium">{selectedAddresses.drop.firstName} {selectedAddresses.drop.lastName}</div>
                          <div className="text-gray-600 text-sm mt-1">
                            {selectedAddresses.drop.address1}, {selectedAddresses.drop.address2}
                            {selectedAddresses.drop.city}
                            +{selectedAddresses.drop.phone}
                          </div>
                          <button
                            className="text-sm text-black hover:text-gray-600 mt-2"
                            onClick={() => setAddressModal("drop")}
                          >
                            Change Address
                          </button>
                        </div>
                      ) : (
                        <div className="p-4 bg-gray-50 rounded-lg">
                          <div className="text-gray-600">No default address</div>
                          <button
                            className="text-sm text-black hover:text-gray-600 mt-2"
                            onClick={() => setAddressModal("drop")}
                          >
                            Select Address
                          </button>
                        </div>
                      ))}
                  </div>

                  {/* Return */}
                  <div className="space-y-4">
                    <h2 className="text-lg font-medium">Return</h2>
                    <select
                      value={collectionData.returnType}
                      onChange={(e) => {
                        setCollectionData({
                          ...collectionData,
                          returnType: e.target.value,
                        });
                      }}
                      className="w-full h-10 pl-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                    >
                      <option value="walk-in">Walk In</option>
                      <option value="delivery">Delivery</option>
                    </select>

                    <div className="relative">
                      <DatePicker
                        selected={collectionData.returnDate}
                        onChange={(date) => {
                          let startD = new Date();
                          startD.setFullYear(date.getFullYear());
                          startD.setMonth(date.getMonth());
                          startD.setDate(date.getDate() - 5);

                          setCollectionData({
                            ...collectionData,
                            returnDate: date,
                            dropDate:
                              date < collectionData.dropDate
                                ? startD
                                : collectionData.dropDate,
                          });
                        }}
                        selectsEnd
                        startDate={collectionData.dropDate}
                        endDate={collectionData.returnDate}
                        minDate={new Date()}
                        nextMonthButtonLabel=">"
                        previousMonthButtonLabel="<"
                        popperClassName="react-datepicker-right"
                        customInput={<ButtonInput />}
                        renderCustomHeader={({
                          date,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div className="flex items-center justify-between px-4 py-2">
                            <span className="text-lg text-gray-900">
                              {format(date, "MMMM yyyy")}
                            </span>

                            <div className="flex gap-1">
                              <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                className={`
                                  p-1 rounded hover:bg-gray-100
                                  ${prevMonthButtonDisabled && "opacity-50 cursor-not-allowed"}
                                `}
                              >
                                <ChevronLeftIcon className="h-5 w-5" />
                              </button>

                              <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                className={`
                                  p-1 rounded hover:bg-gray-100
                                  ${nextMonthButtonDisabled && "opacity-50 cursor-not-allowed"}
                                `}
                              >
                                <ChevronRightIcon className="h-5 w-5" />
                              </button>
                            </div>
                          </div>
                        )}
                      />
                    </div>

                    {collectionData.returnType === "delivery" &&
                      (selectedAddresses.return ? (
                        <div className="p-4 bg-gray-50 rounded-lg">
                          <div className="font-medium">{selectedAddresses.return.firstName} {selectedAddresses.return.lastName}</div>
                          <div className="text-gray-600 text-sm mt-1">
                            {selectedAddresses.return.address1}, {selectedAddresses.return.address2}
                            {selectedAddresses.return.city}
                            +{selectedAddresses.return.phone}
                          </div>
                          <button
                            className="text-sm text-black hover:text-gray-600 mt-2"
                            onClick={() => setAddressModal("return")}
                          >
                            Change Address
                          </button>
                        </div>
                      ) : (
                        <div className="p-4 bg-gray-50 rounded-lg">
                          <div className="text-gray-600">No default address</div>
                          <div className="flex gap-3 mt-2">
                            <button
                              className="text-sm text-black hover:text-gray-600"
                              onClick={() => setAddressModal("return")}
                            >
                              Select Address
                            </button>
                            <button
                              className="text-sm text-black hover:text-gray-600"
                              onClick={() =>
                                setSelectedAddresses({
                                  ...selectedAddresses,
                                  return: selectedAddresses.drop,
                                })
                              }
                            >
                              Same as pickup
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Payment Section */}
              {orderDetails.status === "draft" && (
                <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                  <h2 className="text-lg font-medium mb-4">Payment Method</h2>
                  <ul className="grid grid-cols-2 sm:grid-cols-4 gap-3">
                    <li>
                      <input
                        type="radio"
                        id="answer_cash"
                        name="answer"
                        value="cash"
                        checked={pay === "cash"}
                        onChange={(e) => setPay(e.target.value)}
                        className="peer hidden"
                      />
                      <label
                        htmlFor="answer_cash"
                        className="flex p-4 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-black peer-checked:ring-1 peer-checked:ring-black hover:bg-gray-50"
                      >
                        <div className="w-full text-sm font-medium">Cash</div>
                        <CheckCircleIcon className="h-5 w-5 text-black hidden peer-checked:block" />
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="answer_card"
                        name="answer"
                        value="card"
                        checked={pay === "card"}
                        onChange={(e) => setPay(e.target.value)}
                        className="peer hidden"
                      />
                      <label
                        htmlFor="answer_card"
                        className="flex p-4 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-black peer-checked:ring-1 peer-checked:ring-black hover:bg-gray-50"
                      >
                        <div className="w-full text-sm font-medium">Card</div>
                        <CheckCircleIcon className="h-5 w-5 text-black hidden peer-checked:block" />
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="answer_transfer"
                        name="answer"
                        value="transfer"
                        checked={pay === "transfer"}
                        onChange={(e) => setPay(e.target.value)}
                        className="peer hidden"
                      />
                      <label
                        htmlFor="answer_transfer"
                        className="flex p-4 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-black peer-checked:ring-1 peer-checked:ring-black hover:bg-gray-50"
                      >
                        <div className="w-full text-sm font-medium">Transfer</div>
                        <CheckCircleIcon className="h-5 w-5 text-black hidden peer-checked:block" />
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        id="answer_later"
                        name="answer"
                        value=""
                        checked={pay === ""}
                        onChange={(e) => setPay(e.target.value)}
                        className="peer hidden"
                      />
                      <label
                        htmlFor="answer_later"
                        className="flex p-4 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-black peer-checked:ring-1 peer-checked:ring-black hover:bg-gray-50"
                      >
                        <div className="w-full text-sm font-medium">Later</div>
                        <CheckCircleIcon className="h-5 w-5 text-black hidden peer-checked:block" />
                      </label>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Sidebar */}
            <div className="w-full xl:w-[400px] space-y-6">
              {/* Customer Section */}
              <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                {!customer ? (
                  <div className="space-y-4">
                    <h2 className="text-lg font-medium">Customer Details</h2>
                    <div className="flex gap-3">
                      <div className="relative flex-1">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <UserCircleIcon className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          placeholder="Find Customer"
                          value={searchQuery}
                          className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                          onChange={(e) => {
                            setSearchQuery(e.target.value);
                            findCustomer(e.target.value);
                          }}
                        />
                        {openSuggestions && (
                          <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg max-h-64 overflow-auto border border-gray-100">
                            <ul className="py-1" ref={ref}>
                              {searchCustomers.map((customer, index) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    setCustomer(customer);
                                    setOpenSuggestions(false);
                                  }}
                                  className="px-4 py-2 hover:bg-gray-50 cursor-pointer"
                                >
                                  <div className="font-medium">
                                    {customer.firstName} {customer.lastName}
                                  </div>
                                  <div className="text-sm text-gray-600">
                                    {customer.email}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <button className="flex items-center gap-2 bg-black hover:bg-gray-800 text-white px-4 py-2 rounded-lg transition-colors duration-200">
                        <PlusIcon className="h-4 w-4" />
                        <span className="sr-only">Add Customer</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="space-y-1">
                    <div className="flex items-start justify-between">
                      <h2 className="text-lg font-medium">
                        {customer.firstName} {customer.lastName}
                      </h2>
                      {orderDetails.status === "draft" && (
                        <button
                          onClick={() => setCustomer()}
                          className="p-1 hover:bg-gray-100 rounded-full"
                        >
                          <XMarkIcon className="h-4 w-4" />
                        </button>
                      )}
                    </div>
                    <div className="text-gray-600">{customer.phone}</div>
                    <div className="text-sm text-gray-500">{customer.email}</div>
                  </div>
                )}
              </div>

              {/* Service Location */}
              <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                <h2 className="text-lg font-medium mb-4">Service Location</h2>
                <select
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className="w-full h-10 pl-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                >
                  <option value="">Select service location</option>
                  {locationsList.map((loc) => (
                    <option key={loc.id} value={loc.id}>
                      {loc.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Order Summary */}
              <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                <h2 className="text-lg font-medium mb-4">Order Summary</h2>
                <div className="space-y-3">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Subtotal</span>
                    <span className="font-medium">
                      ₦{orderList
                        .reduce((accumulator, object) => {
                          return (
                            accumulator +
                            (servicesList.filter(
                              (service) => service.code === object.code
                            )[0]?.cost *
                              object.qty) /
                            100
                          );
                        }, 0)
                        .toLocaleString()}
                    </span>
                  </div>

                  {collectionData.dropType === "delivery" && (
                    <div className="flex justify-between items-center">
                      <span className="text-gray-600">Pickup</span>
                      <input
                        type="number"
                        value={dropFee}
                        onChange={(e) => setDropFee(e.target.valueAsNumber)}
                        className="w-24 text-right rounded-lg border border-gray-200 focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                      />
                    </div>
                  )}

                  {collectionData.returnType === "delivery" && (
                    <div className="flex justify-between items-center">
                      <span className="text-gray-600">Delivery</span>
                      <input
                        type="number"
                        value={returnFee}
                        onChange={(e) => setReturnFee(e.target.valueAsNumber)}
                        className="w-24 text-right rounded-lg border border-gray-200 focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                      />
                    </div>
                  )}

                  <div className="flex justify-between items-center">
                    <span className="text-gray-600">Discount</span>
                    <input
                      type="number"
                      value={discount}
                      onChange={(e) => setDiscount(e.target.valueAsNumber)}
                      className="w-24 text-right rounded-lg border border-gray-200 focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                    />
                  </div>

                  <div className="flex justify-between">
                    <span className="text-gray-600">Tax</span>
                    <span>{tax}%</span>
                  </div>

                  <div className="flex justify-between pt-3 border-t border-gray-100">
                    <span className="font-medium">Total</span>
                    <span className="font-medium">
                      <OrderTotalCalculator
                        subTotal={orderList.reduce((accumulator, object) => {
                          return (
                            accumulator +
                            (servicesList.filter(
                              (service) => service.code === object.code
                            )[0]?.cost *
                              object.qty) /
                            100
                          );
                        }, 0)}
                        returnFee={returnFee}
                        dropFee={dropFee}
                        tax={tax}
                        discount={discount}
                      />
                    </span>
                  </div>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex flex-col gap-3">
                <button
                  onClick={() => handleUpdateOrder(false)}
                  disabled={!customer || orderList.length < 1 || loading}
                  className="w-full px-4 py-2 border border-black text-black rounded-lg hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                >
                  {loading ? (
                    <ArrowPathIcon className="h-5 w-5 mx-auto animate-spin" />
                  ) : (
                    "Save"
                  )}
                </button>
                {orderDetails.status === "draft" && (
                  <button
                    onClick={() => handleUpdateOrder(true)}
                    disabled={!customer || orderList.length < 1 || loading}
                    className="w-full px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
                  >
                    {loading ? (
                      <ArrowPathIcon className="h-5 w-5 mx-auto animate-spin" />
                    ) : (
                      "Save & Confirm"
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>

          {addressModal !== "" && (
            <AddressPicker
              type={addressModal}
              customer={customer}
              selectedAddresses={selectedAddresses}
              setSelectedAddresses={setSelectedAddresses}
              close={() => setAddressModal("")}
            />
          )}
        </div>
      )}
    </>
  );
}

const ButtonInput = forwardRef(({ value, onClick }, ref) => (
  <button
    onClick={onClick}
    ref={ref}
    type="button"
    className="inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500"
  >
    {format(new Date(value), "dd MMMM yyyy")}
  </button>
));

export default EditOrder;

import React, { useState, useCallback, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import CustomerService from '../../services/customer.service';
import moment from 'moment';
import { ExclamationCircleIcon, ArrowDownTrayIcon, ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/solid';
import FilterDropdown from '../../components/FilterDropdown';
import Pagination from '../../components/Pagination';

const SEGMENTS = {
  ALL: { value: 'all', label: 'All Customers' },
  DORMANT: { value: 'no_orders_180d', label: 'Dormant Customers', description: 'No orders in last 180 days' },
  LOYAL: { value: 'over_3_orders_6m', label: 'Loyal Customers', description: 'More than 3 orders in the last 6 months' },
  HIGH_VALUE: { value: 'avg_order_value_over_20k', label: 'High Value', description: 'Average order value > ₦20,000' },
  NEW: { value: 'first_order_in_last_30d', label: 'New Customers', description: 'First order in last 30 days' },
  AT_RISK: { value: 'no_orders_90d', label: 'At Risk', description: 'No orders in last 90 days' },
  ONE_TIME: { value: '1_order_alltime', label: 'One-Time', description: 'Only 1 order ever' },
  ZERO_ORDER: { value: 'no_orders_alltime', label: 'Zero Order', description: 'No orders ever' },
};

const SORT_OPTIONS = [
  { value: 'last_order_date', label: 'Last Order Date' },
  { value: 'total_orders', label: 'Total Orders' },
  { value: 'total_spent', label: 'Total Spent' },
  { value: 'avg_order_value', label: 'Average Order Value' }
];

function CustomerSegments() {
  const [searchParams] = useSearchParams();
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(searchParams.get("segment") || SEGMENTS.DORMANT.value);
  const [customerCount, setCustomerCount] = useState(200);
  const [sortConfig, setSortConfig] = useState(searchParams.get("sort") || 'last_order_date');
  const [sortOrder, setSortOrder] = useState(searchParams.get("sortOrder") || '_desc');
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const navigate = useNavigate();

  const isSortableSegment = () => {
    return [SEGMENTS.LOYAL.value, SEGMENTS.DORMANT.value, SEGMENTS.HIGH_VALUE.value, SEGMENTS.NEW.value, SEGMENTS.ONE_TIME.value, SEGMENTS.AT_RISK.value].includes(selectedSegment);
  };

  const getUrlParams = (page, segment, sort, sortOrder) => {
    const params = new URLSearchParams();
    params.set('page', page);
    params.set('segment', segment);
    if (isSortableSegment()) {
      params.set('sort', sort);
      params.set('sortOrder', sortOrder);
    }
    return params.toString();
  };

  const handleSort = (sortField) => {
    if (!isSortableSegment()) return;

    if (sortConfig === sortField) {
      // If clicking the same field, toggle the order
      const newSort = sortOrder === '_desc' ? '_asc' : '_desc';
      setSortOrder(newSort);
      setPage(1);
      navigate(`/dashboard/customer-segments?${getUrlParams(1, selectedSegment, sortConfig, newSort)}`);
    } else {
      // If clicking a new field, set it as sort field with default desc order
      setSortConfig(sortField);
      setSortOrder('_desc');
      setPage(1);
      navigate(`/dashboard/customer-segments?${getUrlParams(1, selectedSegment, sortField, '_desc')}`);
    }
  };

  const navigatePages = (newPage) => {
    setPage(newPage);
    navigate(`/dashboard/customer-segments?${getUrlParams(newPage, selectedSegment, sortConfig, sortOrder)}`);
  };

  const navigateSegment = (segment) => {
    setSelectedSegment(segment);
    setPage(1);
    // Reset sort parameters if segment is not sortable
    if (!isSortableSegment()) {
      setSortConfig('last_order_date');
      setSortOrder('_desc');
    }
    navigate(`/dashboard/customer-segments?${getUrlParams(1, segment, sortConfig, sortOrder)}`);
  };

  const exportCustomerSegments = useCallback(() => {
    CustomerService.exportCustomerSegments(selectedSegment, {
      sortBy: sortConfig + sortOrder,
    }).then((response) => {
      // Create blob from response data
      const blob = new Blob([response], { type: 'text/csv' });
      // Create download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `customer-segment-${selectedSegment}.csv`;
      // Trigger download
      document.body.appendChild(a);
      a.click();
      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
      .catch((err) => {
        console.error('Error exporting customers:', err);
      })
  }, [selectedSegment, sortConfig, sortOrder]);

  // Fetch customers based on segment and filters
  const fetchCustomers = useCallback(() => {
    setIsLoading(true);
    setError(null);

    const params = {
      perPage: 50,
      page: page
    };

    // Only add sort parameters for sortable segments
    if (isSortableSegment()) {
      params.sortBy = sortConfig + sortOrder;
    }

    CustomerService.getCustomerSegments(selectedSegment, params)
      .then((response) => {
        setCustomers(response.customers);
        setCustomerCount(response.count || 200);
      })
      .catch((err) => {
        console.error('Error fetching customers:', err);
        setError('Failed to load customer data. Please try again.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedSegment, page, sortConfig, sortOrder]);

  // Single useEffect to handle all data fetching
  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  return (
    <div className="h-full">
      {/* Header */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-6">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Customer Segments</h1>
          <p className="mt-1 text-sm text-gray-500">
            Analyze and segment customer base
          </p>
        </div>
        <button
          onClick={exportCustomerSegments}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowDownTrayIcon className="h-4 w-4 mr-2" />
          Export
        </button>
      </div>

      {/* Filters */}
      <div className="flex flex-col sm:flex-row gap-4 mb-6">
        <div className="w-full">
          <FilterDropdown
            label="Customer Segment"
            value={selectedSegment}
            options={Object.values(SEGMENTS).map(segment => ({
              value: segment.value,
              label: segment.label
            }))}
            onChange={navigateSegment}
          />
        </div>
      </div>

      {/* Segment Description */}
      {selectedSegment !== SEGMENTS.ALL.value && (
        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6">
          <p className="text-sm text-blue-700">
            {Object.values(SEGMENTS).find(s => s.value === selectedSegment)?.description}
          </p>
        </div>
      )}

      {/* Error Message */}
      {error && (
        <div className="rounded-lg bg-red-50 p-4 border border-red-200">
          <div className="flex">
            <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      {/* Loading State */}
      {isLoading && (
        <div className="space-y-4">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="animate-pulse bg-white rounded-lg shadow p-6">
              <div className="h-4 bg-gray-200 rounded w-1/4"></div>
              <div className="space-y-3 mt-4">
                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                <div className="h-4 bg-gray-200 rounded w-3/4"></div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Customer List */}
      {!isLoading && customers.length > 0 && (
        <div className="bg-white shadow-sm border border-gray-200 rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Customer
                </th>
                <th
                  scope="col"
                  className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${isSortableSegment() ? 'cursor-pointer hover:bg-gray-100' : 'cursor-not-allowed'
                    }`}
                  onClick={() => handleSort('total_orders')}
                >
                  <div className="flex items-center gap-1">
                    Total Orders
                    {isSortableSegment() && sortConfig === 'total_orders' && (
                      sortOrder === '_desc' ?
                        <ArrowDownIcon className="h-4 w-4" /> :
                        <ArrowUpIcon className="h-4 w-4" />
                    )}
                  </div>
                </th>
                <th
                  scope="col"
                  className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${isSortableSegment() ? 'cursor-pointer hover:bg-gray-100' : 'cursor-not-allowed'
                    }`}
                  onClick={() => handleSort('total_spent')}
                >
                  <div className="flex items-center gap-1">
                    Total Spent
                    {isSortableSegment() && sortConfig === 'total_spent' && (
                      sortOrder === '_desc' ?
                        <ArrowDownIcon className="h-4 w-4" /> :
                        <ArrowUpIcon className="h-4 w-4" />
                    )}
                  </div>
                </th>
                <th
                  scope="col"
                  className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${isSortableSegment() ? 'cursor-pointer hover:bg-gray-100' : 'cursor-not-allowed'
                    }`}
                  onClick={() => handleSort('avg_order_value')}
                >
                  <div className="flex items-center gap-1">
                    Avg. Order Value
                    {isSortableSegment() && sortConfig === 'avg_order_value' && (
                      sortOrder === '_desc' ?
                        <ArrowDownIcon className="h-4 w-4" /> :
                        <ArrowUpIcon className="h-4 w-4" />
                    )}
                  </div>
                </th>
                <th
                  scope="col"
                  className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${isSortableSegment() ? 'cursor-pointer hover:bg-gray-100' : 'cursor-not-allowed'
                    }`}
                  onClick={() => handleSort('last_order_date')}
                >
                  <div className="flex items-center gap-1">
                    Last Order
                    {isSortableSegment() && sortConfig === 'last_order_date' && (
                      sortOrder === '_desc' ?
                        <ArrowDownIcon className="h-4 w-4" /> :
                        <ArrowUpIcon className="h-4 w-4" />
                    )}
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  First Order
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {customers.map((customer) => (
                <tr key={customer.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div>
                        <div className="text-sm font-medium text-gray-900">
                          {customer.firstName} {customer.lastName}
                        </div>
                        <div className="text-sm text-gray-500">
                          {customer.phone}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {customer.totalOrders}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    ₦{(customer.totalSpent / 100)?.toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    ₦{(customer.avgOrderValue / 100)?.toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {customer.lastOrder ? moment(customer.lastOrder.createdAt).format('MMM D, YYYY') : 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {customer.firstOrder ? moment(customer.firstOrder.createdAt).format('MMM D, YYYY') : 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <Link
                      to={"/dashboard/orders?customerId=" + customer.id}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      View Orders
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="border-t border-gray-100 px-6 py-4 flex flex-col sm:flex-row sm:items-center justify-between gap-4">
            <small className="text-gray-500 text-center sm:text-left">
              Showing {customers.length} of {customerCount} results
            </small>

            <div className="flex justify-center sm:justify-end">
              <Pagination
                currentPage={parseInt(page) || 1}
                totalItems={customerCount}
                itemsPerPage={50}
                onPageChange={navigatePages}
              />
            </div>
          </div>
        </div>
      )}

      {/* Empty State */}
      {!isLoading && customers.length === 0 && (
        <div className="text-center py-12 bg-white rounded-lg shadow-sm border border-gray-200">
          <p className="text-sm text-gray-500">No customers found in this segment.</p>
        </div>
      )}


    </div>
  );
}

export default CustomerSegments; 
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import OrderService from "../services/order.service";

export default function DueOrderCard(props) {
  const [dueOrders, setDueOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getDueOrders = () => {
    setIsLoading(true);
    OrderService.getDueOrders()
      .then((response) => {
        setDueOrders(response.orders);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDueOrders();
  }, []);

  return (
    <div>
      <h2 className="text-lg font-semibold mb-4">
        Due Orders{" "}
        {!isLoading && <span className="text-xs text-red-400">{dueOrders.length}</span>}
      </h2>
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
        {isLoading ? (
          <div className="animate-pulse space-y-4">
            {[...Array(4)].map((_, i) => (
              <div key={i} className="flex items-center space-x-4">
                <div className="w-10 h-10 bg-gray-200 rounded-full"></div>
                <div className="flex-1 space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                </div>
              </div>
            ))}
          </div>
        ) : dueOrders.length === 0 ? (
          <div className="h-[252px] flex items-center justify-center text-gray-500">
            <p>No due orders at the moment</p>
          </div>
        ) : (
          <ul className="overflow-auto h-[252px] divide-y divide-gray-100 -mx-6 px-6">
            {dueOrders.map((due, index) => {
              return (
                <li
                  key={index}
                  className="py-2 hover:bg-gray-50 -mx-6 px-6"
                >
                  <Link to={"/dashboard/order/" + due.id} className="flex items-center">
                    {/* Avatar with Initials */}
                    <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-300 text-gray-700 font-bold uppercase mr-4">
                      {due.customer.firstName?.[0]}
                      {/* {due.customer.lastName?.[0]} */}
                    </div>

                    {/* Customer Name & Order Due Text */}
                    <p className="text-gray-900 leading-none">
                      <span className="font-bold">
                        {due.customer.firstName} {due.customer.lastName}
                      </span>
                      's order is due{" "}
                      {due.return.date > new Date()
                        ? moment(due.return.date).toNow()
                        : moment(due.return.date).fromNow()}
                    </p>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

function Pagination({ 
  currentPage = 1, 
  totalItems, 
  itemsPerPage = 20, 
  onPageChange,
  className = ''
}) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  
  // Generate page numbers to show
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 11; // Increased from 7 to 11
    
    if (totalPages <= maxVisiblePages) {
      // Show all pages if total is less than max visible
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always show first page
      pageNumbers.push(1);
      
      let startPage = Math.max(2, currentPage - 4); // Show 4 pages before current (increased from 2)
      let endPage = Math.min(totalPages - 1, currentPage + 4); // Show 4 pages after current (increased from 2)
      
      // Adjust start and end to always show 9 pages in the middle
      if (startPage <= 3) {
        endPage = Math.min(totalPages - 1, 10);
      }
      if (endPage >= totalPages - 2) {
        startPage = Math.max(2, totalPages - 9);
      }
      
      // Add ellipsis after first page if needed
      if (startPage > 2) {
        pageNumbers.push('...');
      }
      
      // Add middle pages
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      
      // Add ellipsis before last page if needed
      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }
      
      // Always show last page
      if (totalPages > 1) {
        pageNumbers.push(totalPages);
      }
    }
    
    return pageNumbers;
  };

  return (
    <div className={`flex flex-wrap gap-2 items-center mt-3 ${className}`}>
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage <= 1}
        className="bg-white border border-gray-300 p-1 hover:bg-gray-100 disabled:bg-gray-100 rounded-full"
        aria-label="Previous page"
      >
        <ChevronLeftIcon className="h-6 w-6" />
      </button>
      
      <div className="flex flex-wrap gap-1">
        {getPageNumbers().map((pageNum, index) => (
          pageNum === '...' ? (
            <span key={`ellipsis-${index}`} className="px-2 py-1">...</span>
          ) : (
            <button
              key={pageNum}
              onClick={() => onPageChange(pageNum)}
              className={`min-w-[2.5rem] px-3 py-1 rounded ${
                currentPage === pageNum
                  ? 'bg-gray-800 text-white'
                  : 'bg-white border border-gray-300 hover:bg-gray-100'
              }`}
            >
              {pageNum}
            </button>
          )
        ))}
      </div>

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage >= totalPages}
        className="bg-white border border-gray-300 p-1 hover:bg-gray-100 disabled:bg-gray-100 rounded-full"
        aria-label="Next page"
      >
        <ChevronRightIcon className="h-6 w-6" />
      </button>
      
      {/* <small className="text-right flex-1 mt-2 sm:mt-0">
        Page {currentPage}/{totalPages} - {totalItems} results
      </small> */}
    </div>
  );
}

export default Pagination; 
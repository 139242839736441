import React, { useState, useEffect, useRef } from "react";
import {
  TrashIcon,
  PencilIcon,
  XMarkIcon,
  InformationCircleIcon,
  PlusIcon,
  ArrowPathIcon,
  CalendarDaysIcon,
  MagnifyingGlassIcon,
  ExclamationCircleIcon,
  PhoneIcon,
} from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import CustomerService from "../../services/customer.service";
import validator from "validator";
import AddCustomerModal from "../../components/AddCustomerModal";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../components/Pagination";
import moment from "moment";

function Customers() {
  const [searchParams] = useSearchParams();
  const [openNewCModal, setOpenNewCModal] = useState(false);
  const [openEditCModal, setOpenEditCModal] = useState(false);
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [perPage, setPerPage] = useState(searchParams.get("perPage") || 1);
  const [filter, setFilter] = useState(searchParams.get("filter") || "");
  const [customerCount, setcustomerCount] = useState();
  const [editCustomer, setEditCustomer] = useState({});
  const [customerList, setCustomerList] = useState([]);
  const [delCount, setDelCount] = useState(0);
  const [listLoading, setListLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [listError, setListError] = useState();
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const [searchCustomers, setSearchCustomers] = useState([]);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  const [form, setForm] = useState({});

  const auth = useSelector((state) => state.auth);

  const handleClickOutsideSearch = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setOpenSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideSearch, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideSearch, true);
    };
  }, []);

  const findCustomer = (q) => {
    setOpenSuggestions(true);
    CustomerService.getCustomers(q, 10, 1)
      .then((resp) => {
        setSearchCustomers(resp.customers);
      })
      .catch((err) => { });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    console.log("value", value);
    setFilter(value);
    if (value.trim()) {
      findCustomer(value);
    } else {
      setSearchCustomers([]);
      setOpenSuggestions(false);
    }
  };

  const handleSearchSelect = (customer) => {
    setFilter(customer.firstName + " " + customer.lastName);
    setOpenSuggestions(false);
    getCustomers(customer.firstName + " " + customer.lastName, 20, 1);
  };

  const toggleNewCModal = () => {
    setOpenNewCModal(!openNewCModal);
  };
  const toggleEditCModal = (customer) => {
    setEditCustomer(customer);
    setOpenEditCModal(!openEditCModal);
  };

  const navigatePages = (page, filter) => {
    navigate(`/dashboard/customers?page=${page}&filter=${filter}`);
    setPage(page);
    getCustomers(filter, 20, page);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditCustomer({ ...editCustomer, [name]: value });
  };

  const handleSelectCustomer = (index) => {
    customerList[index].isChecked = !customerList[index].isChecked;
    setCustomerList([...customerList]);
    setDelCount(
      customerList.filter((customer) => customer.isChecked === true).length
    );
  };

  const handleAddCustomer = () => {
    setLoading(true);
    setError();
    if (!form.firstName || !form.phone || !form.email) {
      setError("Required fields cannot be empty");
      setLoading(false);
      return;
    }
    if (
      validator.isEmpty(form.firstName) ||
      validator.isEmpty(form.phone) ||
      validator.isEmpty(form.email)
    ) {
      setError("Required fields cannot be empty");
      setLoading(false);
      return;
    }
    if (!validator.isEmail(form.email)) {
      setError("This is not a valid email");
      setLoading(false);
      return;
    }
    CustomerService.newCustomer(form)
      .then((resp) => {
        setForm({});
        setLoading(false);
        toggleNewCModal();
        getCustomers("", 20, 1);
      })
      .catch((err) => {
        setError(err.response.data);
        setLoading(false);
      });
  };

  const handleEditCustomer = () => {
    setLoading(true);
    setError();
    if (!editCustomer.firstName || !editCustomer.phone || !editCustomer.email) {
      setError("Required fields cannot be empty");
      setLoading(false);
      return;
    }
    if (
      validator.isEmpty(editCustomer.firstName) ||
      validator.isEmpty(editCustomer.phone) ||
      validator.isEmpty(editCustomer.email)
    ) {
      setError("Required fields cannot be empty");
      setLoading(false);
      return;
    }
    if (!validator.isEmail(editCustomer.email)) {
      setError("This is not a valid email");
      setLoading(false);
      return;
    }
    CustomerService.updateCustomer(editCustomer)
      .then((resp) => {
        setLoading(false);
        toggleEditCModal({});
        getCustomers("", 20, 1);
      })
      .catch((err) => {
        setError(err.response.data);
        setLoading(false);
      });
  };

  const getCustomers = (query, perPage, page) => {
    setListLoading(true);
    setListError(false);
    CustomerService.getCustomers(query, perPage, page)
      .then((resp) => {
        setCustomerList(resp.customers);
        setcustomerCount(resp.count);
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
        setListError("Something went wrong.");
      });
  };

  useEffect(() => {
    getCustomers(
      searchParams.get("filter") || "",
      20,
      searchParams.get("page")
    );
  }, []);

  return (
    <div className="h-full">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row sm:items-center gap-4 mb-6">
        <h1 className="text-2xl font-semibold flex-1">Customers</h1>

        <div className="flex flex-wrap items-center gap-3">
          {/* Search Box */}
          <div className="relative w-full sm:w-auto" ref={searchRef}>
            <input
              type="text"
              placeholder="Search customers..."
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
              defaultValue={filter}
              onChange={(e) =>
                setFilter(e.target.value)
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  getCustomers(filter, 20, 1);
                }
              }}
            />
            <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            {openSuggestions && searchCustomers.length > 0 && (
              <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg max-h-64 overflow-auto border border-gray-100">
                <ul className="py-1">
                  {searchCustomers.map((customer, index) => (
                    <li
                      key={index}
                      onClick={() => handleSearchSelect(customer)}
                      className="px-4 py-2 hover:bg-gray-50 cursor-pointer"
                    >
                      <div className="font-medium">
                        {customer.firstName} {customer.lastName}
                      </div>
                      <div className="text-sm text-gray-600">
                        {customer.email}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {delCount > 0 && (
            <button className="flex items-center gap-2 text-red-600 hover:text-red-700 py-2 px-4 border border-red-200 hover:border-red-300 rounded-lg transition-colors duration-200">
              <TrashIcon className="h-4 w-4" />
              <span>Delete {delCount} selected</span>
            </button>
          )}

          <button
            className="flex items-center gap-2 bg-black hover:bg-gray-800 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200 w-auto"
            onClick={toggleNewCModal}
          >
            <PlusIcon className="h-4 w-4" />
            <span>New Customer</span>
          </button>
        </div>
      </div>

      {/* Table Section */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
        <div className="hidden md:block">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 text-xs font-medium text-gray-500 uppercase tracking-wider">
                <th className="w-12 py-3 px-6">
                  <input
                    type="checkbox"
                    className="w-4 h-4 rounded border-gray-300 text-black focus:ring-gray-500"
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setCustomerList(customerList.map(c => ({ ...c, isChecked })));
                      setDelCount(isChecked ? customerList.length : 0);
                    }}
                  />
                </th>
                <th className="py-3 px-6 text-left">Name</th>
                {/* <th className="py-3 px-6 text-left">Phone Number</th> */}
                {/* <th className="py-3 px-6 text-left max-md:hidden">Email Address</th> */}
                <th className="py-3 px-6 text-left">Orders</th>
                <th className="py-3 px-6 text-left">Lifetime Value</th>
                <th className="py-3 px-6 text-left">Avg. Order Value</th>
                <th className="py-3 px-6 text-left">Date Added</th>
                <th className="py-3 px-6 text-center">Action</th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-100">
              {listLoading ? (
                [...Array(5)].map((_, i) => (
                  <tr key={i}>
                    <td colSpan={8} className="py-3 px-6">
                      <div className="animate-pulse flex items-center space-x-4">
                        <div className="h-4 w-4 bg-gray-200 rounded"></div>
                        <div className="flex-1 space-y-4">
                          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : listError ? (
                <tr>
                  <td colSpan={6} className="py-8 text-center text-gray-500">
                    <div className="flex flex-col items-center gap-2">
                      <ExclamationCircleIcon className="h-5 w-5 text-gray-400" />
                      <p>{listError}</p>
                      <button
                        onClick={() => getCustomers(filter, 20, page)}
                        className="text-black hover:text-gray-600 font-medium"
                      >
                        Try again
                      </button>
                    </div>
                  </td>
                </tr>
              ) : customerList.length === 0 ? (
                <tr>
                  <td colSpan={6} className="py-8 text-center text-gray-500">
                    <p>No customers found</p>
                  </td>
                </tr>
              ) : (
                customerList.map((customer, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="py-3 px-6">
                      <input
                        type="checkbox"
                        className="w-4 h-4 rounded border-gray-300 text-black focus:ring-gray-500"
                        checked={customer.isChecked || false}
                        onChange={() => handleSelectCustomer(index)}
                      />
                    </td>
                    <td className="py-3 px-6">
                      <p>
                        <Link
                          to={"/dashboard/orders?customerId=" + customer.id}
                          className="font-medium text-gray-900 hover:text-black"
                        >
                          {customer.firstName} {customer.lastName}
                        </Link>
                      </p>
                      <small className="text-xs text-gray-500">+{customer.phone} {customer.email && "| " + customer.email}</small>
                    </td>
                    {/* <td className="py-3 px-6 text-gray-600 max-md:hidden">{customer.email}</td> */}
                    <td className="py-3 px-6 text-gray-600">
                      {customer.value.totalOrders === 1
                        ? "1 order"
                        : customer.value.totalOrders + " orders"}
                    </td>
                    <td className="py-3 px-6 text-gray-600">
                      ₦{(customer.value.totalSpent / 100).toLocaleString()}
                    </td>
                    <td className="py-3 px-6 text-gray-600">
                      ₦{(customer.value.avgOrderValue / 100).toLocaleString()}
                    </td>
                    <td className="py-3 px-6 text-gray-600">
                      {moment(customer.createdAt).format("DD MMM. YYYY")}
                    </td>
                    <td className="py-3 px-6">
                      <div className="flex justify-center">
                        <button
                          onClick={() => toggleEditCModal(customer)}
                          className="flex items-center gap-2 text-gray-600 hover:text-black"
                        >
                          <PencilIcon className="h-4 w-4" />
                          <span>Edit</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className="md:hidden">
          {listLoading ? (
            <div className="p-4">
              <div className="animate-pulse space-y-4">
                {[...Array(3)].map((_, i) => (
                  <div key={i} className="flex items-center space-x-4">
                    <div className="h-4 w-4 bg-gray-200 rounded"></div>
                    <div className="flex-1 space-y-3">
                      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : listError ? (
            <div className="p-8 text-center text-gray-500">
              <div className="flex flex-col items-center gap-2">
                <ExclamationCircleIcon className="h-5 w-5 text-gray-400" />
                <p>{listError}</p>
                <button
                  onClick={() => getCustomers(filter, 20, page)}
                  className="text-black hover:text-gray-600 font-medium"
                >
                  Try again
                </button>
              </div>
            </div>
          ) : customerList.length === 0 ? (
            <div className="p-8 text-center text-gray-500">
              <p>No customers found</p>
            </div>
          ) : (
            <ul className="divide-y divide-gray-100">
              {customerList.map((customer, index) => (
                <li key={index} className="p-4 hover:bg-gray-50">
                  <div className="flex items-start gap-3">
                    <input
                      type="checkbox"
                      className="mt-1 w-4 h-4 rounded border-gray-300 text-black focus:ring-gray-500"
                      checked={customer.isChecked || false}
                      onChange={() => handleSelectCustomer(index)}
                    />

                    <div className="flex-1">
                      <Link to={"/dashboard/orders?customerId=" + customer.id} className="block">
                        <div className="flex items-start justify-between">
                          <div>
                            <div className="font-medium text-gray-900">
                              {customer.firstName} {customer.lastName}
                            </div>
                            <small className="text-xs text-gray-500">
                              +{customer.phone} {customer.email && "| " + customer.email}
                            </small>
                          </div>
                          <div className="text-sm text-gray-600">
                            {customer.value.totalOrders === 1
                              ? "1 order"
                              : customer.value.totalOrders + " orders"}
                          </div>
                        </div>
                      </Link>

                      <div className="mt-3 flex items-center gap-3">
                        <button
                          onClick={() => toggleEditCModal(customer)}
                          className="inline-flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
                        >
                          <PencilIcon className="h-4 w-4" />
                          <span>Edit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="border-t border-gray-100 px-6 py-4 flex flex-col sm:flex-row sm:items-center justify-between gap-4">
          <small className="text-gray-500 text-center sm:text-left">
            Showing {customerList.length} of {customerCount} results
          </small>

          <div className="flex justify-center sm:justify-end">
            <Pagination
              currentPage={parseInt(page) || 1}
              totalItems={customerCount}
              itemsPerPage={20}
              onPageChange={(newPage) => navigatePages(newPage, filter)}
            />
          </div>
        </div>
      </div>

      <AddCustomerModal
        open={openNewCModal}
        close={() => setOpenNewCModal(false)}
        getCustomers={() => getCustomers("", 20, 1)}
      />

      {/* Edit Customer Modal */}
      <div
        className={`fixed inset-0 bg-gray-900/50 z-50 transition-opacity duration-200 ${openEditCModal ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
      >
        <div className="min-h-screen px-4 text-center">
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="px-6 py-6">
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-lg font-medium text-gray-900">Edit Customer</h3>
                <button
                  onClick={() => toggleEditCModal({})}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>

              {error && (
                <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg flex items-center gap-2 text-red-700">
                  <InformationCircleIcon className="h-5 w-5 flex-shrink-0" />
                  <p>{error}</p>
                </div>
              )}

              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      First Name
                    </label>
                    <input
                      name="firstName"
                      value={editCustomer.firstName || ""}
                      onChange={handleEditChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                      placeholder="James"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Last Name
                    </label>
                    <input
                      name="lastName"
                      value={editCustomer.lastName || ""}
                      onChange={handleEditChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                      placeholder="Nwafor"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Phone Number
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center px-3 border-r border-gray-300">
                      <PhoneIcon className="h-4 w-4" />
                    </div>
                    <input
                      name="phone"
                      value={editCustomer.phone || ""}
                      onChange={handleEditChange}
                      className="w-full pl-12 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                      placeholder="XXXXXXXXXXX"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Email Address
                  </label>
                  <input
                    name="email"
                    value={editCustomer.email || ""}
                    onChange={handleEditChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                    placeholder="james@example.com"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Date of Birth
                  </label>
                  <div className="relative">
                    <input
                      name="dob"
                      value={editCustomer.dob || ""}
                      onChange={handleEditChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                      placeholder="DD/MM/YYYY"
                    />
                    <CalendarDaysIcon className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>

              <div className="mt-6 flex justify-end gap-3">
                <button
                  onClick={() => toggleEditCModal({})}
                  className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={handleEditCustomer}
                  disabled={loading}
                  className="px-4 py-2 bg-black hover:bg-gray-800 text-white rounded-lg flex items-center gap-2"
                >
                  {loading ? (
                    <>
                      <ArrowPathIcon className="h-4 w-4 animate-spin" />
                      <span>Saving...</span>
                    </>
                  ) : (
                    <span>Save Changes</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;

import React, { forwardRef, useState, useEffect, useRef } from "react";
import {
  TrashIcon,
  UserCircleIcon,
  PlusIcon,
  ShoppingCartIcon,
  MinusIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckCircleIcon,
  CalendarDaysIcon,
  XMarkIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import CustomerService from "../../services/customer.service";
import validator from "validator";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import OrderService from "../../services/order.service";
import OrderTotalCalculator from "../../components/OrderTotalCalculator";
import AddCustomerModal from "../../components/AddCustomerModal";
import AddressPicker from "../../components/AddressPicker";
import PickupService from "../../services/pickup.service";

function CreatePickup() {
  const [dropFee, setdropFee] = useState(0);
  const [returnFee, setreturnFee] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(0);
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const [addressModal, setAddressModal] = useState("");
  const [selectedAddresses, setSelectedAddresses] = useState({});
  const [searchCustomers, setSearchCustomers] = useState([]);
  const [collectionData, setCollectionData] = useState({
    dropType: "walk-in",
    dropDate: new Date(),
  });
  const [servicesList, setServicesList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [preferredDate, setPreferredDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [customer, setCustomer] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [openNewCModal, setOpenNewCModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pairsEstimate, setPairsEstimate] = useState(2);

  const navigate = useNavigate();

  const ref = useRef(null);

  const handleCreateRequest = (confirm) => {
    setLoading(true);
    const request = {
      address: selectedAddresses.drop,
      source: "STORE",
      customerId: customer.id,
      logisticsOption: "van",
      pairsEstimate: pairsEstimate,
      preferredDate: preferredDate.toISOString().split("T")[0], // ISO 8601 format : YYYY-MM-DD
    };
    console.log(request);
    PickupService.createRequest(request)
      .then((resp) => {
        setLoading(false);
        navigate("/dashboard/pickups");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleClickOutsideSearch = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenSuggestions(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideSearch, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideSearch, true);
    };
  }, []);
  const getServices = () => {
    OrderService.getServices()
      .then((resp) => {
        setServicesList(resp.services);
      })
      .catch((err) => {});
  };

  const getAddresses = (customer) => {
    CustomerService.getCustomerAddresses(customer)
      .then((resp) => {
        // setAddress(resp);
        let defaultAddress = resp.addresses.filter(
          (address) => address.default
        );
        console.log(resp.addresses);
        setSelectedAddresses({
          drop: defaultAddress[0],
        });
      })
      .catch((err) => {});
  };
  const findCustomer = (q) => {
    setOpenSuggestions(true);
    CustomerService.getCustomers(q, 10, 1)
      .then((resp) => {
        setSearchCustomers(resp.customers);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getServices();
  }, []);
  return (
    <div className="h-full">
      <div className="flex flex-col sm:flex-row sm:items-center gap-4 mb-6">
        <h1 className="text-2xl font-semibold">New Pickup Request</h1>
      </div>

      <div className="flex flex-col xl:flex-row gap-6">
        {/* Main Content */}
        <div className="flex-1 space-y-6">
          {/* Customer Section */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
            {!customer ? (
              <div className="space-y-4">
                <h2 className="text-lg font-medium">Customer Details</h2>
                <div className="flex gap-3">
                  <div className="relative flex-1">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <UserCircleIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      placeholder="Find Customer"
                      value={searchQuery}
                      className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        findCustomer(e.target.value);
                      }}
                    />
                    {openSuggestions && (
                      <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg max-h-64 overflow-auto border border-gray-100">
                        <ul className="py-1" ref={ref}>
                          {searchCustomers.map((customer, index) => (
                            <li
                              key={index}
                              onClick={() => {
                                setCustomer(customer);
                                getAddresses(customer.id);
                                setOpenSuggestions(false);
                              }}
                              className="px-4 py-2 hover:bg-gray-50 cursor-pointer"
                            >
                              <div className="font-medium">
                                {customer.firstName} {customer.lastName}
                              </div>
                              <div className="text-sm text-gray-600">
                                {customer.email}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <button
                    className="flex items-center gap-2 bg-black hover:bg-gray-800 text-white px-4 py-2 rounded-lg transition-colors duration-200"
                    onClick={() => setOpenNewCModal(true)}
                  >
                    <PlusIcon className="h-4 w-4" />
                    <span className="sr-only">Add Customer</span>
                  </button>
                </div>
              </div>
            ) : (
              <div className="space-y-1">
                <div className="flex items-start justify-between">
                  <h2 className="text-lg font-medium">
                    {customer.firstName} {customer.lastName}
                  </h2>
                  <button
                    onClick={() => setCustomer()}
                    className="p-1 hover:bg-gray-100 rounded-full"
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </button>
                </div>
                <div className="text-gray-600">{customer.phone}</div>
                <div className="text-sm text-gray-500">{customer.email}</div>
              </div>
            )}
          </div>

          {/* Pickup Details */}
          {customer && (
            <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
              <div className="space-y-6">
                <h2 className="text-lg font-medium">Pickup Details</h2>

                {/* Date Selection */}
                <div className="space-y-2">
                  <label className="font-medium text-gray-900">Preferred Date</label>
                  <div className="relative">
                    <DatePicker
                      selected={preferredDate}
                      name="preferredDate"
                      onChange={(date) => {
                        setPreferredDate(date);
                      }}
                      selectsStart
                      startDate={preferredDate}
                      minDate={new Date()}
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="<"
                      popperClassName="react-datepicker-left"
                      customInput={<ButtonInput />}
                      renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div className="flex items-center justify-between px-4 py-2">
                          <span className="text-lg text-gray-900">
                            {format(date, "MMMM yyyy")}
                          </span>

                          <div className="flex gap-1">
                            <button
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                              className={`
                                p-1 rounded hover:bg-gray-100
                                ${prevMonthButtonDisabled && "opacity-50 cursor-not-allowed"}
                              `}
                            >
                              <ChevronLeftIcon className="h-5 w-5" />
                            </button>

                            <button
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                              className={`
                                p-1 rounded hover:bg-gray-100
                                ${nextMonthButtonDisabled && "opacity-50 cursor-not-allowed"}
                              `}
                            >
                              <ChevronRightIcon className="h-5 w-5" />
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>

                {/* Address */}
                <div className="space-y-2">
                  <label className="font-medium text-gray-900">Pickup Address</label>
                  {selectedAddresses.drop ? (
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <div className="font-medium">
                        {selectedAddresses.drop.firstName} {selectedAddresses.drop.lastName}
                      </div>
                      <div className="text-gray-600 text-sm mt-1">
                        <p>{selectedAddresses.drop.address1}, {selectedAddresses.drop.address2}</p>
                        <p>{selectedAddresses.drop.city}</p>
                        <p>+{selectedAddresses.drop.phone}</p>
                      </div>
                      <button
                        className="text-sm text-black hover:text-gray-600 mt-2"
                        onClick={() => setAddressModal("drop")}
                      >
                        Change Address
                      </button>
                    </div>
                  ) : (
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <div className="text-gray-600">No default address</div>
                      <button
                        className="text-sm text-black hover:text-gray-600 mt-2"
                        onClick={() => setAddressModal("drop")}
                      >
                        Select Address
                      </button>
                    </div>
                  )}
                </div>

                {/* Number of Pairs */}
                <div className="space-y-2">
                  <label className="font-medium text-gray-900">Estimated Number of Pairs</label>
                  <input
                    name="pairsEstimate"
                    type="number"
                    onChange={(e) => setPairsEstimate(e.target.value)}
                    defaultValue={pairsEstimate}
                    placeholder="10"
                    className="w-full h-10 pl-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                  />
                </div>
              </div>
            </div>
          )}

          {/* Action Button */}
          <button
            onClick={() => handleCreateRequest(true)}
            disabled={!customer || loading}
            className="w-full px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
          >
            {loading ? (
              <ArrowPathIcon className="h-5 w-5 mx-auto animate-spin" />
            ) : (
              "Create Request"
            )}
          </button>
        </div>
      </div>

      <AddCustomerModal
        open={openNewCModal}
        close={() => setOpenNewCModal(false)}
        getCustomers={() => findCustomer("")}
      />

      {addressModal !== "" && (
        <AddressPicker
          type={addressModal}
          customer={customer}
          selectedAddresses={selectedAddresses}
          setSelectedAddresses={setSelectedAddresses}
          close={() => setAddressModal("")}
        />
      )}
    </div>
  );
}
const ButtonInput = forwardRef(({ value, onClick }, ref) => (
  <button
    onClick={onClick}
    ref={ref}
    type="button"
    className="inline-flex justify-start w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500"
  >
    {format(new Date(value), "dd MMMM yyyy")}
  </button>
));
export default CreatePickup;

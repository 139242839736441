import React, { useState } from "react";
import {
  Bars3Icon,
  UserIcon,
  LockClosedIcon,
  XMarkIcon,
  PlusIcon,
  UsersIcon,
  HomeIcon,
  ShoppingBagIcon,
  TruckIcon,
  BuildingOffice2Icon,
  MapPinIcon,
  ChevronDownIcon,
  ChartBarIcon,
} from "@heroicons/react/24/solid";
import { Outlet, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import "./Dashboard.css";
import { logout } from "../../actions/auth";

import classNames from "classnames";
import toast, { Toaster } from "react-hot-toast";
import styles from "./Dashboard.module.css";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/solid";
import orderService from "../../services/order.service";
import DueOrderNotification from "../../components/DueOrderNotification";

function Dashboard() {
  const [isAsideOpen, setAsideOpen] = useState(false);
  const [isHMenuOpen, setHMenuOpen] = useState(false);
  const [isCustomersOpen, setIsCustomersOpen] = useState(false);
  const navigate = useNavigate();
  let page = useLocation();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const user = JSON.parse(localStorage.getItem("user"));

  const toggleAside = () => {
    setAsideOpen(!isAsideOpen);
  };

  const toggleHeaderMenu = () => {
    setHMenuOpen(!isHMenuOpen);
  };
  const handleLogout = () => {
    dispatch(logout());
    navigate("/auth/login");
  };
  if (!auth.isLoggedIn) {
    return <Navigate to="/auth/login" replace={true} />;
  }

  const notify = (type, header, message) => {
    toast.custom(
      (t) => (
        <div
          className={classNames([
            styles.notificationWrapper,
            t.visible ? "bottom-0" : "-bottom-96",
            type === "error" ? "bg-red-600" : "bg-green-600",
          ])}
        >
          <div className={styles.iconWrapper}>
            {type === "error" && <XMarkIcon className="h-6 w-6" />}
            {type === "success" && <CheckBadgeIcon className="h-6 w-6" />}
          </div>
          <div className={styles.contentWrapper}>
            <h1>{header}</h1>
            <p className="text-left">{message}</p>
          </div>
          <div className={styles.closeIcon} onClick={() => toast.dismiss(t.id)}>
            <XCircleIcon className="h-6 w-6" />
          </div>
        </div>
      ),
      { id: "unique-notification", position: "bottom-center" }
    );
  };

  return (
    <main className="min-h-screen w-full">
      <header className="flex w-full fixed lg:relative z-10 items-center justify-between border-b border-gray-100 bg-white p-2">
        <div className="flex items-center space-x-2">
          <button
            type="button"
            className="text-3xl"
            onClick={toggleAside}
          >
            <i className="bx bx-menu"></i>
            <Bars3Icon className="h-6 w-6 text-black" />
          </button>
          <div className="text-black mr-6">
            <span className="font-semibold text-2xl tracking-tight">Care</span>
          </div>
        </div>

        <div className="flex space-x-2">
          <Link
            to={"dashboard/order/create"}
            className="p-3 rounded-full bg-black"
          >
            <PlusIcon className="h-5 text-white" />
          </Link>
          <div>
            <Toaster />
          </div>
          <button
            type="button"
            onClick={handleLogout}
            className="p-3 bg-black rounded-full "
          >
            <LockClosedIcon className="h-5 text-white" />
          </button>

          {/* <div
            className={`${
              isHMenuOpen ? "" : "hidden"
            } absolute right-2 mt-1 w-48 divide-y divide-gray-200 rounded-md border border-gray-200 bg-white shadow-md`}
          >
            {/* <div className="flex items-center space-x-2 p-2">
              <div className="font-medium">'Tife Pariola</div>
            </div> 

            <div className="p-2">
              <button
                onClick={handleLogout}
                className="flex justify-items-end w-full space-x-2 transition hover:text-blue-600"
              >
                <LockClosedIcon className="h-6 w-6" />
                <div>Logout</div>
              </button>
            </div>
          </div>*/}
        </div>
      </header>

      <div className="flex screen">
        <aside
          className={`fixed lg:relative mt-16 lg:mt-0 lg:translate-x-0 transition-all duration-300 ease-in-out z-10 h-[calc(100vh-64px)] flex flex-col bg-white border-r border-gray-200 ${isAsideOpen ? "w-72 p-4" : "w-16 p-2"
            }`}
        >
          <nav className="space-y-1">
            <Link
              to="/dashboard/home"
              className={`flex items-center space-x-3 rounded-lg px-4 py-3 text-sm font-medium transition-colors ${page.pathname === "/dashboard/home"
                ? "bg-black text-white"
                : "text-gray-700 hover:bg-gray-100"
                }`}
              onClick={() => isAsideOpen && toggleAside()}
            >
              <HomeIcon className="h-5 w-5 flex-shrink-0" />
              <span className={`${isAsideOpen ? 'inline-block' : 'hidden'}`}>Home</span>
            </Link>
            <Link
              to="/dashboard/customers"
              className={`flex items-center space-x-3 rounded-lg px-4 py-3 text-sm transition-colors ${page.pathname === "/dashboard/customers"
                ? "bg-black text-white"
                : "text-gray-700 hover:bg-gray-100"
                }`}
              onClick={() => isAsideOpen && toggleAside()}
            >
              <UsersIcon className="h-5 w-5 flex-shrink-0" />
              <span className={`${isAsideOpen ? 'inline-block' : 'hidden'}`}>Customers</span>
            </Link>
            {user.role === "admin" && (
              <Link
                to="/dashboard/customer-segments"
                className={`flex items-center space-x-3 rounded-lg px-4 py-3 text-sm transition-colors ${page.pathname === "/dashboard/customer-segments"
                  ? "bg-black text-white"
                  : "text-gray-700 hover:bg-gray-100"
                  }`}
                onClick={() => isAsideOpen && toggleAside()}
              >
                <ChartBarIcon className="h-4 w-4 flex-shrink-0" />
                <span className={`${isAsideOpen ? 'inline-block' : 'hidden'}`}>Customer Segments</span>
              </Link>
            )}
            <Link
              to="/dashboard/orders"
              className={`flex items-center space-x-3 rounded-lg px-4 py-3 text-sm font-medium transition-colors ${page.pathname.includes("order")
                ? "bg-black text-white"
                : "text-gray-700 hover:bg-gray-100"
                }`}
              onClick={() => isAsideOpen && toggleAside()}
            >
              <ShoppingBagIcon className="h-5 w-5 flex-shrink-0" />
              <span className={`${isAsideOpen ? 'inline-block' : 'hidden'}`}>Orders</span>
            </Link>
            <Link
              to="/dashboard/pickups"
              className={`flex items-center space-x-3 rounded-lg px-4 py-3 text-sm font-medium transition-colors ${page.pathname.includes("pickup")
                ? "bg-black text-white"
                : "text-gray-700 hover:bg-gray-100"
                }`}
              onClick={() => isAsideOpen && toggleAside()}
            >
              <TruckIcon className="h-5 w-5 flex-shrink-0" />
              <span className={`${isAsideOpen ? 'inline-block' : 'hidden'}`}>Pickup Requests</span>
            </Link>

            {user.role === "admin" && (
              <>
                <div className={`relative my-4 ${isAsideOpen ? 'block' : 'hidden'}`}>
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-200"></div>
                  </div>
                  <div className="relative flex justify-start">
                    <span className="bg-white pr-3 text-sm font-semibold text-gray-500">Settings</span>
                  </div>
                </div>

                <Link
                  to="/dashboard/logistics"
                  className={`flex items-center space-x-3 rounded-lg px-4 py-3 text-sm font-medium transition-colors ${page.pathname.includes("logistics")
                    ? "bg-black text-white"
                    : "text-gray-700 hover:bg-gray-100"
                    }`}
                  onClick={() => isAsideOpen && toggleAside()}
                >
                  <MapPinIcon className="h-5 w-5 flex-shrink-0" />
                  <span className={`${isAsideOpen ? 'inline-block' : 'hidden'}`}>Manage Zones</span>
                </Link>
                <Link
                  to="/dashboard/users"
                  className={`flex items-center space-x-3 rounded-lg px-4 py-3 text-sm font-medium transition-colors ${page.pathname.includes("users")
                    ? "bg-black text-white"
                    : "text-gray-700 hover:bg-gray-100"
                    }`}
                  onClick={() => isAsideOpen && toggleAside()}
                >
                  <UserIcon className="h-5 w-5 flex-shrink-0" />
                  <span className={`${isAsideOpen ? 'inline-block' : 'hidden'}`}>Manage Users</span>
                </Link>
                <Link
                  to="/dashboard/locations"
                  className={`flex items-center space-x-3 rounded-lg px-4 py-3 text-sm font-medium transition-colors ${page.pathname.includes("locations")
                    ? "bg-black text-white"
                    : "text-gray-700 hover:bg-gray-100"
                    }`}
                  onClick={() => isAsideOpen && toggleAside()}
                >
                  <BuildingOffice2Icon className="h-5 w-5 flex-shrink-0" />
                  <span className={`${isAsideOpen ? 'inline-block' : 'hidden'}`}>Manage Locations</span>
                </Link>
              </>
            )}
          </nav>


        </aside>

        <div className="w-full p-4 ml-16 mt-16 lg:ml-0 lg:mt-0">
          <Outlet context={notify} />
        </div>
      </div>
    </main>
  );
}
export default Dashboard;

import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import OrderService from "../../services/order.service";
import reportService from "../../services/report.service";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";
import DueOrderCard from "../../components/DueOrderCard";
import { OrderStatCard } from "../../components/OrderStatCard";
import { ArrowDownIcon, CalendarDaysIcon, ChevronDownIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import FilterDropdown from "../../components/FilterDropdown";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell, BarChart, Bar, ResponsiveContainer } from 'recharts';

function Home() {
  var today = new Date();
  const [stats, setStats] = useState();
  const [prevStats, setPrevStats] = useState();
  const [openLocPicker, toggleLocPicker] = useState(false);
  const [servicesStats, setservicesStats] = useState([]);
  const [prevServicesStats, setPrevServicesStats] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [serviceLocationId, setServiceLocationId] = useState("");
  const [servicesList, setServicesList] = useState([]);
  // ✅ Default: Month to Date | ✅ Default Last Period: Previous Month to Date
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf("month").toDate(),
    endDate: moment().toDate(),
  });

  const [lastP, setLastPeriod] = useState({
    startDate: moment().subtract(1, "months").startOf("month").toDate(),
    endDate: moment().subtract(1, "months").date(Math.min(moment().date(), moment().subtract(1, "months").daysInMonth())).toDate(),
  });

  const user = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getServiceDetail = (code) => {
    let service = servicesList.filter((f) => f.code === code);
    return service;
  };

  const getLocations = () => {
    OrderService.getLocations()
      .then((resp) => {
        setLocationsList(resp.locations);
      })
      .catch((err) => { });
  };
  const getServices = () => {
    OrderService.getServices()
      .then((resp) => {
        setServicesList(resp.services);
      })
      .catch((err) => { });
  };


  // ✅ Memoized `req` to prevent unnecessary recalculations
  const req = useMemo(
    () => ({
      start: moment(dateRange.startDate).format("YYYY-MM-DD"),
      end: moment(dateRange.endDate).format("YYYY-MM-DD"),
      serviceLocationId,
    }),
    [dateRange, serviceLocationId]
  );

  // ✅ Memoized `getOverview` to prevent infinite loops
  const getOverview = useCallback(
    (currentReq, lastPeriod) => {
      if (!currentReq?.start || !currentReq?.end || !lastPeriod?.startDate || !lastPeriod?.endDate) return;

      setIsLoading(true);
      setError(null);

      Promise.all([
        reportService.getOverview(currentReq),
        reportService.getOverview({
          start: moment(lastPeriod.startDate).format("YYYY-MM-DD"),
          end: moment(lastPeriod.endDate).format("YYYY-MM-DD"),
          serviceLocationId: currentReq.serviceLocationId,
        }),
        reportService.getServiceOverview(currentReq),
        reportService.getServiceOverview({
          start: moment(lastPeriod.startDate).format("YYYY-MM-DD"),
          end: moment(lastPeriod.endDate).format("YYYY-MM-DD"),
          serviceLocationId: currentReq.serviceLocationId,
        }),
      ])
        .then(([overview, prevOverview, serviceOverview, prevServiceOverview]) => {
          setStats(overview.overview);
          setPrevStats(prevOverview.overview);
          setservicesStats(serviceOverview.serviceOverview);
          setPrevServicesStats(prevServiceOverview.serviceOverview);
          setError(null);
        })
        .catch((e) => {
          console.error("Error fetching reports:", e);
          setError("Failed to load dashboard data. Please try again.");
          setStats(null);
          setPrevStats(null);
          setservicesStats([]);
          setPrevServicesStats([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [] // No dependencies needed as we pass everything as parameters
  );

  // ✅ Combined effect to handle both date changes and fetch data
  useEffect(() => {
    if (!req?.start || !req?.end) return;

    const periodInput = detectPeriod(req.start, req.end);
    const lastPeriod = getLastPeriod(periodInput);
    if (!lastPeriod || typeof lastPeriod === "string") return;

    setLastPeriod((prevLastP) => {
      const prevStart = moment(prevLastP.startDate).format("YYYY-MM-DD");
      const prevEnd = moment(prevLastP.endDate).format("YYYY-MM-DD");
      const newStart = moment(lastPeriod.startDate).format("YYYY-MM-DD");
      const newEnd = moment(lastPeriod.endDate).format("YYYY-MM-DD");

      const newLastP = prevStart !== newStart || prevEnd !== newEnd
        ? { startDate: lastPeriod.startDate.toDate(), endDate: lastPeriod.endDate.toDate() }
        : prevLastP;

      // Call getOverview here with both current and comparison periods
      getOverview(req, newLastP);

      return newLastP;
    });
  }, [req, getOverview]);

  // Add new effect to handle manual comparison date changes
  useEffect(() => {
    if (!req?.start || !req?.end || !lastP?.startDate || !lastP?.endDate) return;
    getOverview(req, lastP);
  }, [lastP, getOverview]);

  useEffect(() => {
    getServices();
    getLocations();
  }, []);

  const formatDateRange = (range) => {
    if (!range?.startDate || !range?.endDate) return '';
    const start = moment(range.startDate);
    const end = moment(range.endDate);

    // If years are different
    if (start.format('YYYY') !== end.format('YYYY')) {
      return `${start.format('MMM D, YYYY')} - ${end.format('MMM D, YYYY')}`;
    }

    // If months are different but same year
    if (start.format('MMM') !== end.format('MMM')) {
      return `${start.format('MMM D')} - ${end.format('MMM D')}, ${start.format('YYYY')}`;
    }

    // Same month and year
    return `${start.format('MMM')} ${start.format('D')}-${end.format('D')}, ${start.format('YYYY')}`;
  };

  // Prepare revenue trend data
  const prepareRevenueTrendData = () => {
    if (!stats || !prevStats) return [];

    const currentStart = moment(dateRange.startDate);
    const currentEnd = moment(dateRange.endDate);
    const prevStart = moment(lastP.startDate);
    const prevEnd = moment(lastP.endDate);

    // Calculate daily revenue for current period
    const dailyRevenue = {};
    const days = currentEnd.diff(currentStart, 'days') + 1;

    for (let i = 0; i < days; i++) {
      const date = moment(currentStart).add(i, 'days');
      dailyRevenue[date.format('MMM D')] = {
        name: date.format('MMM D'),
        revenue: Math.round((stats.orders.revenue / days) * (0.8 + Math.random() * 0.4)), // Simulate daily variation
        prevRevenue: Math.round((prevStats.orders.revenue / days) * (0.8 + Math.random() * 0.4))
      };
    }

    return Object.values(dailyRevenue);
  };

  const revenueTrendData = prepareRevenueTrendData();

  /* Format large numbers to K and M */
  const formatYAxisNumber = (number) => {
    if (number >= 1000000) {
      return `${(number / 1000000).toFixed(1)}M`;
    } else if (number >= 1000) {
      return `${(number / 1000).toFixed(1)}K`;
    }
    return number;
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="col-span-full md:col-span-2">
        {/* 📅 Date Picker */}
        <div className="flex flex-col md:flex-row md:items-center gap-3 text-sm mb-6">
          <div className="relative flex-1 md:flex-none md:w-auto">
            <div className="absolute inset-0 pointer-events-none flex gap-2 items-center px-3 text-gray-600 shadow-sm border border-gray-200 rounded-md whitespace-nowrap">
              <CalendarDaysIcon className="w-4 h-4 flex-shrink-0" />
              <span className="truncate">{formatDateRange(dateRange)}</span>
            </div>
            <div className="w-full md:w-auto" style={{ minWidth: 'calc(1.5rem + 1.5rem + 1rem + 16ch)' }}>
              <Datepicker
                inputClassName="w-full py-2 pl-2 rounded-md focus:ring-0 font-normal bg-white border text-gray-600 opacity-0"
                readOnly
                useRange={false}
                showShortcuts
                showFooter
                separator=" to "
                displayFormat="MMM D"
                configs={datepickerConfigs(today)}
                maxDate={new Date()}
                value={dateRange}
                toggleClassName="hidden"
                onChange={setDateRange}
              />
            </div>
          </div>
          <div className="relative flex-1 md:flex-none md:w-auto">
            <div className="absolute inset-0 pointer-events-none flex gap-2 items-center px-3 text-gray-600 shadow-sm border border-gray-200 rounded-md whitespace-nowrap">
              <span className="truncate">Compare to: {formatDateRange(lastP)}</span>
            </div>
            <div className="w-full md:w-auto" style={{ minWidth: 'calc(11ch + 1.5rem + 16ch)' }}>
              <Datepicker
                inputClassName="w-full py-2 pl-2 rounded-md focus:ring-0 font-normal bg-white border text-gray-600 opacity-0"
                readOnly
                useRange={false}
                showFooter
                separator=" to "
                displayFormat="MMM D"
                configs={datepickerConfigs(today)}
                maxDate={today}
                value={lastP}
                startFrom={lastP.startDate}
                toggleClassName="hidden"
                onChange={setLastPeriod}
              />
            </div>
          </div>
          <div className="relative flex-1 md:flex-none md:w-auto">
            <FilterDropdown
              label="Location"
              value={serviceLocationId}
              options={[
                { value: "", label: "All Locations" },
                ...locationsList.map(location => ({
                  value: location.id,
                  label: location.name
                }))
              ]}
              onChange={(value) => {
                setServiceLocationId(value);
                toggleLocPicker(false);
              }}
            />
          </div>
        </div>

        {/* Error Message */}
        {error && (
          <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg flex items-center gap-2 text-red-700">
            <ExclamationCircleIcon className="w-5 h-5 flex-shrink-0" />
            <p>{error}</p>
          </div>
        )}

        {/* Loading State */}
        {isLoading && (
          <div className="space-y-8">
            <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-6 gap-4">
              {[...Array(6)].map((_, i) => (
                <div key={i} className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                  <div className="animate-pulse space-y-3">
                    <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                    <div className="h-8 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                  </div>
                </div>
              ))}
            </div>
            {user.role === "admin" && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="col-span-2">
                  <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                    <div className="animate-pulse">
                      <div className="h-[300px] bg-gray-200 rounded"></div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                    <div className="animate-pulse">
                      <div className="h-[300px] bg-gray-200 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* 📊 Order Stats */}
        {!isLoading && stats && prevStats && (
          <div className="space-y-8">
            {/* Key Metrics */}
            <div>
              <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-6 gap-4">
                <OrderStatCard
                  title="Ready Orders"
                  value={stats.orders.ready}
                  // prevValue={prevStats.orders.ready}
                  className="bg-white rounded-xl shadow-sm border border-gray-100 p-6"
                />
                <OrderStatCard
                  title="Delivered Orders"
                  value={stats.orders.delivered}
                  // prevValue={prevStats.orders.delivered}
                  className="bg-white rounded-xl shadow-sm border border-gray-100 p-6"
                />
                <OrderStatCard
                  title="Unpaid Orders"
                  value={stats.orders.unpaid}
                  // prevValue={prevStats.orders.unpaid}
                  className="bg-white rounded-xl shadow-sm border border-gray-100 p-6"
                />
                <OrderStatCard
                  title="Avg. Order Value"
                  value={(stats.orders.revenue - stats.orders.logistics) / (stats.orders.count || 1)}
                  prevValue={(prevStats.orders.revenue - prevStats.orders.logistics) / (prevStats.orders.count || 1)}
                  type="amount"
                  className="bg-white rounded-xl shadow-sm border border-gray-100 p-6"
                />
                <OrderStatCard
                  title="Avg. Pair Value"
                  value={servicesStats.length > 0 ? (stats.orders.revenue - stats.orders.logistics) / servicesStats.reduce((acc, val) => acc + val.value, 0) : 0}
                  prevValue={prevServicesStats.length > 0 ? (prevStats.orders.revenue - prevStats.orders.logistics) / prevServicesStats.reduce((acc, val) => acc + val.value, 0) : 0}
                  type="amount"
                  className="bg-white rounded-xl shadow-sm border border-gray-100 p-6"
                />
                <OrderStatCard
                  title="Total Orders"
                  value={stats.orders.count}
                  prevValue={prevStats.orders.count}
                  className="bg-white rounded-xl shadow-sm border border-gray-100 p-6"
                />
              </div>
            </div>

            {/* Charts Section */}
            {user.role === "admin" && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* Revenue Overview Chart */}
                <div className="col-span-2">
                  <h2 className="text-lg font-semibold mb-4">Revenue Overview</h2>
                  <div className="bg-white rounded-xl shadow-sm border border-gray-100 py-6 text-sm">
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart
                        data={[
                          {
                            name: 'Total Revenue',
                            current: (stats.orders.revenue / 100),
                            previous: (prevStats.orders.revenue / 100)
                          },
                          {
                            name: 'Service Revenue',
                            current: (stats.orders.revenue - stats.orders.logistics) / 100,
                            previous: (prevStats.orders.revenue - prevStats.orders.logistics) / 100
                          },
                          {
                            name: 'Delivery Fee',
                            current: (stats.orders.logistics / 100),
                            previous: (prevStats.orders.logistics / 100)
                          },
                          {
                            name: 'Unpaid Revenue',
                            current: (stats.orders.unpaidrevenue / 100),
                            previous: (prevStats.orders.unpaidrevenue / 100)
                          }
                        ]}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis tickFormatter={formatYAxisNumber} />
                        <Tooltip formatter={(value) => `${value.toLocaleString('en-NG', { style: "currency", currency: "NGN" })}`} />
                        <Legend />
                        <Bar dataKey="current" name="Current Period" fill="#000" />
                        <Bar dataKey="previous" name="Previous Period" fill="rgba(0, 0, 0, 0.5)" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>

                {/* Order Types Distribution */}
                <div>
                  <h2 className="text-lg font-semibold mb-4">Order Types Distribution</h2>
                  <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={[
                            { name: 'Walk-In', value: stats.orders.dropType.walk_in || 0 },
                            { name: 'Delivery', value: stats.orders.dropType.delivery || 0 }
                          ]}
                          cx="50%"
                          cy="50%"
                          innerRadius={50}
                          outerRadius={80}
                          fill="#8884d8"
                          paddingAngle={2}
                          dataKey="value"
                        >
                          {[
                            '#000',
                            '#B33F40'
                          ].map((color, index) => (
                            <Cell key={`cell-${index}`} fill={color} />
                          ))}
                        </Pie>
                        <Tooltip formatter={(value) => value.toLocaleString()} />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            )}

            {/* Performance Metrics */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="col-span-1">
                  <h2 className="text-lg font-semibold mb-4">Services Breakdown</h2>
                  <div className="bg-white rounded-xl shadow-sm border border-gray-100 w-full">
                    <div className="overflow-x-auto">
                      <table className="w-full">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Service</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Count</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Change</th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {servicesStats.map((service, index) => (
                            <tr key={index}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {getServiceDetail(service.key)[0]?.name}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {service.value}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm">
                                <span className={`
                                inline-flex items-center rounded-md px-2 py-0.5 text-[0.65rem] font-medium ring-1 ring-red-600/10 ring-inset ${calculatePercentageDifference(
                                  prevServicesStats[index]?.value || 0,
                                  service.value
                                ) >= 0
                                    ? 'bg-green-50 text-green-700'
                                    : 'bg-red-50 text-red-700'
                                  }`}>
                                  {Math.round(
                                    calculatePercentageDifference(
                                      prevServicesStats[index]?.value || 0,
                                      service.value
                                    )
                                  )}%
                                </span>
                              </td>
                            </tr>
                          ))}
                          {/* <tr className="bg-gray-50 font-semibold">
                            <td className="px-6 py-4 whitespace-nowrap text-sm">Total</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              {servicesStats.reduce((acc, val) => acc + val.value, 0)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              <span className={`
                                inline-flex items-center rounded-md px-2 py-0.5 text-[0.65rem] font-medium ring-1 ring-red-600/10 ring-inset ${calculatePercentageDifference(
                                prevServicesStats.reduce((acc, val) => acc + val.value, 0),
                                servicesStats.reduce((acc, val) => acc + val.value, 0)
                              ) >= 0
                                  ? 'bg-green-100 text-green-800'
                                  : 'bg-red-100 text-red-800'
                                }`}>
                                {Math.round(
                                  calculatePercentageDifference(
                                    prevServicesStats.reduce((acc, val) => acc + val.value, 0),
                                    servicesStats.reduce((acc, val) => acc + val.value, 0)
                                  )
                                )}%
                              </span>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4">
                  <DueOrderCard />
                </div>
                {/* Revenue Trend Chart */}
                {/* <div>
                  <h2 className="text-lg font-semibold mb-4">Revenue Trend</h2>
                  <div className="bg-white rounded-xl shadow-sm border border-gray-100 py-6 text-sm">
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={revenueTrendData}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis tickFormatter={formatYAxisNumber} />
                        <Tooltip formatter={(value) => `${value.toLocaleString('en-NG', { style: "currency", currency: "NGN" })}`} />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="revenue"
                          name="Current Period"
                          stroke="#4F46E5"
                          strokeWidth={2}
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey="prevRevenue"
                          name="Previous Period"
                          stroke="#94A3B8"
                          strokeWidth={2}
                          dot={false}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div> */}
              </div>

            {/* Charts Section */}
            {user.role === "admin" && stats && prevStats && (
              <div className="space-y-8">



              </div>
            )}
          </div>
        )}
      </div>


    </div>
  );
}

function getLastPeriod(input) {
  if (!input) return "Invalid date format";

  let startDate, endDate;

  if (input.toLowerCase() === "today") {
    // Yesterday
    startDate = moment().subtract(1, "days").startOf("day");
    endDate = moment().subtract(1, "days").endOf("day");
  } else if (input.toLowerCase() === "yesterday") {
    // Day before yesterday
    startDate = moment().subtract(2, "days").startOf("day");
    endDate = moment().subtract(2, "days").endOf("day");
  } else if (input.toLowerCase() === "last 7 days") {
    // Previous 7 days before the current period
    console.log("7ddd");
    startDate = moment().subtract(13, "days").startOf("day");
    endDate = moment().subtract(7, "days").endOf("day");
  } else if (input.toLowerCase() === "last 14 days") {
    // Previous 14 days before the current period
    startDate = moment().subtract(27, "days").startOf("day");
    endDate = moment().subtract(14, "days").endOf("day");
  } else if (input.toLowerCase() === "this week") {
    // Previous week
    startDate = moment().subtract(1, "weeks").startOf("week");
    endDate = moment().subtract(1, "weeks").endOf("week");
  } else if (input.toLowerCase() === "month to date") {
    // Same days in previous month
    console.log("monthhhhh");
    const currentDate = moment();
    startDate = moment().subtract(1, "months").startOf("month");
    endDate = moment().subtract(1, "months").date(Math.min(currentDate.date(), moment().subtract(1, "months").daysInMonth()));
  } else if (input.toLowerCase() === "last month") {
    // Month before last month
    startDate = moment().subtract(2, "months").startOf("month");
    endDate = moment().subtract(2, "months").endOf("month");
  } else if (input.toLowerCase() === "this year") {
    // Previous year
    startDate = moment().subtract(1, "years").startOf("year");
    endDate = moment().subtract(1, "years").endOf("year");
  } else if (input.toLowerCase() === "last year") {
    // Year before last year
    startDate = moment().subtract(2, "years").startOf("year");
    endDate = moment().subtract(2, "years").endOf("year");
  } else {
    // Custom date range - shift back by same number of days
    const dates = input.split(" - ");
    if (dates.length === 2) {
      startDate = moment(dates[0], "YYYY-MM-DD", true);
      endDate = moment(dates[1], "YYYY-MM-DD", true);

      if (!startDate.isValid() || !endDate.isValid()) {
        return "Invalid date format";
      }

      const diffDays = endDate.diff(startDate, "days");
      startDate = moment(startDate).subtract(diffDays + 1, "days");
      endDate = moment(endDate).subtract(diffDays + 1, "days");
    } else {
      return "Invalid date format";
    }
  }

  return { startDate, endDate };
}

/* ✅ Datepicker Configs */
const datepickerConfigs = (today) => ({
  shortcuts: [
    { text: "Today", period: { start: new Date(), end: new Date() } },
    {
      text: "Yesterday",
      period: {
        start: moment().subtract(1, "days").toDate(),
        end: moment().subtract(1, "days").toDate(),
      },
    },
    {
      text: "Last 7 Days",
      period: {
        start: moment().subtract(6, "days").startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
      },
    },
    {
      text: "Last 14 Days",
      period: {
        start: moment().subtract(13, "days").startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
      },
    },
    {
      text: "This Week",
      period: {
        start: moment().startOf("week").toDate(),
        end: moment().endOf("week").toDate(),
      },
    },
    {
      text: "Month to Date",
      period: {
        start: moment().startOf("month").toDate(),
        end: moment().toDate(),
      },
    },
    {
      text: "Last Month",
      period: {
        start: moment().subtract(1, "months").startOf("month").toDate(),
        end: moment().subtract(1, "months").endOf("month").toDate(),
      },
    },
    {
      text: "This Year",
      period: { start: moment().startOf("year").toDate(), end: today },
    },
    {
      text: "Last Year",
      period: {
        start: moment().subtract(1, "years").startOf("year").toDate(),
        end: moment().subtract(1, "years").endOf("year").toDate(),
      },
    },
  ],
});

/* ✅ Detect Period */
const detectPeriod = (start, end) => {
  const startDate = moment(start, "YYYY-MM-DD"); // Convert string to Moment
  const endDate = moment(end, "YYYY-MM-DD"); // Convert string to Moment

  // Today
  if (moment().isSame(startDate, "day") && moment().isSame(endDate, "day"))
    return "today";

  // Yesterday
  if (moment().subtract(1, "days").isSame(startDate, "day") && moment().subtract(1, "days").isSame(endDate, "day"))
    return "yesterday";

  // Month to date - check this before last 7 days since it's more specific
  if (
    moment().startOf("month").isSame(startDate, "day") &&
    moment().isSame(endDate, "day")
  )
    return "month to date";

  // Last 7 days
  if (
    moment().subtract(6, "days").startOf("day").isSame(startDate, "day") &&
    moment().endOf("day").isSame(endDate, "day")
  )
    return "last 7 days";

  // Last 14 days
  if (
    moment().subtract(13, "days").startOf("day").isSame(startDate, "day") &&
    moment().endOf("day").isSame(endDate, "day")
  )
    return "last 14 days";

  // This week
  if (
    moment().startOf("week").isSame(startDate, "day") &&
    moment().endOf("week").isSame(endDate, "day")
  )
    return "this week";

  // This year
  if (
    moment().startOf("year").isSame(startDate, "day") &&
    moment().isSame(endDate, "day")
  )
    return "this year";

  // Last month
  if (
    moment().subtract(1, "months").startOf("month").isSame(startDate, "day") &&
    moment().subtract(1, "months").endOf("month").isSame(endDate, "day")
  )
    return "last month";

  // Last year
  if (
    moment().subtract(1, "years").startOf("year").isSame(startDate, "day") &&
    moment().subtract(1, "years").endOf("year").isSame(endDate, "day")
  )
    return "last year";

  return `${start} - ${end}`; // Custom range
};

function calculatePercentageDifference(original, newNumber) {
  if (original === 0) {
    return newNumber === 0 ? 0 : 100;
  }

  const difference = newNumber - original;
  const percentageChange = (difference / original) * 100;

  return percentageChange;
}
export default Home;

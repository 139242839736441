import React, { useState, useRef, useEffect } from 'react';
import { ChevronDownIcon } from "@heroicons/react/24/solid";

function FilterDropdown({ 
  label,
  options,
  value,
  onChange,
  className = ''
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const selectedOption = options.find(option => 
    option.value === value
  ) || options[0];

  return (
    <div className={`relative inline-block ${className}`} ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex items-center justify-between px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 min-w-[160px] w-full"
      >
        <span className="truncate">
          {label}: <span className="font-medium">{selectedOption.label}</span>
        </span>
        <ChevronDownIcon
          className={`ml-2 h-5 w-5 text-gray-400 transition-transform duration-200 flex-shrink-0 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>

      {isOpen && (
        <div className="absolute left-0 z-10 mt-2 min-w-full bg-white rounded-md shadow-lg max-h-60 overflow-auto whitespace-nowrap">
          <div className="py-1">
            {options.map((option) => (
              <button
                key={option.value}
                onClick={() => {
                  onChange(option.value);
                  setIsOpen(false);
                }}
                className={`${
                  option.value === value
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-700 hover:bg-gray-50'
                } w-full text-left px-4 py-2 text-sm flex items-center justify-between`}
              >
                {option.label}
                {option.value === value && (
                  <span className="text-blue-600 ml-2">•</span>
                )}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterDropdown; 
import React, { useState, useEffect } from "react";
import {
  TrashIcon,
  PencilIcon,
  PlusIcon,
  ArrowPathIcon,
  ClockIcon,
  CheckIcon,
  XMarkIcon,
  ShoppingCartIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import CustomerService from "../../services/customer.service";
import { format } from "date-fns";
import validator from "validator";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import OrderService from "../../services/order.service";
import OrderTotalCalculator from "../../components/OrderTotalCalculator";
import DropdownComponent from "../../components/DropdownComponent";
import ConfirmModalComponent from "../../components/ConfirmModal";
import CustomDropDown from "../../components/CustomDropDown";
import pickupService from "../../services/pickup.service";
import ConfirmPickupModalComponent from "../../components/ConfirmPickupModal";
import Pagination from "../../components/Pagination";

function PickupRequests() {
  const [searchParams] = useSearchParams();
  const [openNewCModal, setOpenNewCModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [cancelRequest, setCancelRequest] = useState(false);
  const [confirmPickup, setConfirmPickup] = useState({});
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [filter, setFilter] = useState(searchParams.get("filter") || "");
  const [requestsCount, setRequestsCount] = useState();

  const [editCustomer, setEditCustomer] = useState({});
  const [dropRequests, setDropRequests] = useState([]);
  const [delCount, setDelCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [error, setError] = useState();
  const [listError, setListError] = useState();
  const [requestNumber, setRequestNumber] = useState("");

  const [form, setForm] = useState({});

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const navigatePages = (page, filter, request) => {
    // 👇️ navigate to /
    navigate(
      `/dashboard/pickups?page=${page}&filter=${filter}&request=${request}`
    );
    setPage(page);
    getRequests(page, filter, request);
  };
  const handleSelectRequest = (index) => {
    dropRequests[index].isChecked = !dropRequests[index].isChecked;
    setDropRequests(dropRequests);
    setDelCount(
      dropRequests.filter((request) => request.isChecked === true).length
    );
  };
  const getRequests = (page, filter, request) => {
    setListLoading(true);
    setListError(false);
    pickupService
      .getRequests(filter, page || 1, request)
      .then((resp) => {
        setDropRequests(resp.requests.requests);
        setRequestsCount(resp.requests.count);
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
        setListError("Something went wrong.");
        console.log(err);
      });
  };
  useEffect(() => {
    if (searchParams.get("request"))
      setRequestNumber(searchParams.get("request"));
    getRequests(
      searchParams.get("page"),
      searchParams.get("filter"),
      searchParams.get("request")
    );
  }, []);
  return (
    <div className="h-full">
      <div className="flex flex-col lg:flex-row lg:items-center gap-4 mb-6">
        <h1 className="text-2xl font-semibold">Pickup Requests</h1>
        <div className="flex flex-wrap gap-4 lg:flex-1 w-full">
          <div className="relative w-full sm:max-w-sm">
            <input
              className="w-full pl-4 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
              placeholder="Search with request number..."
              type="number"
              onChange={(e) => {
                setRequestNumber(e.target.value);
                navigatePages(1, "", e.target.value);
              }}
              defaultValue={requestNumber}
            />
          </div>

          <Link
            to={"/dashboard/pickup/create"}
            className="inline-flex items-center justify-center gap-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors duration-200 whitespace-nowrap"
          >
            <PlusIcon className="h-4 w-4" />
            <span>Create Pickup</span>
          </Link>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden mb-6">
        <div className="hidden md:block">
          <table className="w-full">
            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left">Pickup ID</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Request Date</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Customer Name</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Pair Estimate</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Discount Code</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Type</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Status</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Preferred Pickup Date</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Action</div>
                </th>
              </tr>
            </thead>

            <tbody className="text-sm divide-y divide-gray-100">
              {listLoading ? (
                [...Array(5)].map((_, index) => (
                  <tr key={index} className="animate-pulse">
                    <td className="p-2">
                      <div className="h-4 w-16 bg-gray-200 rounded"></div>
                    </td>
                    <td className="p-2">
                      <div className="h-4 w-24 bg-gray-200 rounded"></div>
                    </td>
                    <td className="p-2">
                      <div className="h-4 w-32 bg-gray-200 rounded"></div>
                    </td>
                    <td className="p-2">
                      <div className="h-4 w-12 bg-gray-200 rounded"></div>
                    </td>
                    <td className="p-2">
                      <div className="h-4 w-20 bg-gray-200 rounded"></div>
                    </td>
                    <td className="p-2">
                      <div className="h-4 w-16 bg-gray-200 rounded"></div>
                    </td>
                    <td className="p-2">
                      <div className="h-5 w-20 bg-gray-200 rounded-full"></div>
                    </td>
                    <td className="p-2">
                      <div className="h-4 w-24 bg-gray-200 rounded"></div>
                    </td>
                    <td className="p-2">
                      <div className="flex items-center gap-2">
                        <div className="h-8 w-8 bg-gray-200 rounded"></div>
                        <div className="h-8 w-8 bg-gray-200 rounded"></div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : listError ? (
                <tr>
                  <td colSpan={9} className="p-4">
                    <div className="flex flex-col items-center justify-center gap-2">
                      <p className="text-gray-500">{listError}</p>
                      <button
                        onClick={() => getRequests(page, filter, requestNumber)}
                        className="text-sm text-black hover:text-gray-600"
                      >
                        Try again
                      </button>
                    </div>
                  </td>
                </tr>
              ) : dropRequests.length === 0 ? (
                <tr>
                  <td colSpan={9} className="p-4">
                    <div className="flex flex-col items-center justify-center gap-2">
                      <p className="text-gray-500">No pickup requests found</p>
                    </div>
                  </td>
                </tr>
              ) : (
                dropRequests.map((pickup, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="p-2">
                      <Link
                        to={"/dashboard/pickup/" + pickup.id}
                        className="text-left font-medium text-black hover:text-gray-600"
                      >
                        #{pickup.number}
                      </Link>
                    </td>
                    <td className="p-2">
                      <div className="text-left text-gray-600">
                        {format(new Date(pickup.createdAt), "dd MMM. yyyy")}
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="font-medium text-gray-900">
                        {pickup.customer.firstName} {pickup.customer.lastName}
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="text-gray-600">{pickup.pairsEstimate}</div>
                    </td>
                    <td className="p-2">
                      <div className="text-gray-600">{pickup.discountCode || '-'}</div>
                    </td>
                    <td className="p-2">
                      <div className="capitalize text-gray-600">{pickup.logisticsOption}</div>
                    </td>
                    <td className="p-2">
                      <span
                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${pickup.status === "cancelled"
                            ? "bg-gray-100 text-gray-800"
                            : pickup.status === "scheduled"
                              ? "bg-yellow-100 text-yellow-800"
                              : pickup.status === "pending"
                                ? "bg-red-100 text-red-800"
                                : "bg-green-100 text-green-800"
                          }`}
                      >
                        {pickup.status}
                      </span>
                    </td>
                    <td className="p-2">
                      <div className="text-left text-gray-600">
                        {format(new Date(pickup.preferredDate), "dd MMM. yyyy")}
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="flex items-center gap-2">
                        {pickup.status !== "collected" && pickup.status !== "cancelled" && (
                          <Link
                            to={"/dashboard/pickup/" + pickup.id + "/edit"}
                            className="inline-flex items-center gap-2 p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
                          >
                            <PencilIcon className="h-4 w-4" />
                            <span className="sr-only">Edit</span>
                          </Link>
                        )}
                        {pickup.status === "pending" && (
                          <button
                            onClick={() => {
                              setConfirmPickup(pickup);
                              setOpenConfirmModal(true);
                            }}
                            className="inline-flex items-center gap-2 p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
                          >
                            <ClockIcon className="h-4 w-4" />
                            <span className="sr-only">Mark as Scheduled</span>
                          </button>
                        )}
                        {pickup.status === "scheduled" && (
                          <Link
                            to={"/dashboard/order/create/" + pickup.id}
                            className="inline-flex items-center gap-2 p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
                          >
                            <ShoppingCartIcon className="h-4 w-4" />
                            <span className="sr-only">Create Order</span>
                          </Link>
                        )}
                        {pickup.status !== "collected" && pickup.status !== "cancelled" && (
                          <button
                            onClick={() => {
                              setConfirmPickup(pickup);
                              setOpenConfirmModal(true);
                              setCancelRequest(true);
                            }}
                            className="inline-flex items-center gap-2 p-2 text-red-600 hover:text-red-900 hover:bg-red-50 rounded-lg"
                          >
                            <XMarkIcon className="h-4 w-4" />
                            <span className="sr-only">Cancel</span>
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="md:hidden">
          {listLoading ? (
            <div className="divide-y divide-gray-100">
              {[...Array(4)].map((_, index) => (
                <div key={index} className="p-4 animate-pulse">
                  <div className="flex items-start justify-between mb-2">
                    <div className="space-y-2">
                      <div className="h-4 w-24 bg-gray-200 rounded"></div>
                      <div className="h-5 w-20 bg-gray-200 rounded"></div>
                    </div>
                    <div className="h-6 w-20 bg-gray-200 rounded-full"></div>
                  </div>
                  <div className="space-y-2 mb-3">
                    <div className="h-5 w-36 bg-gray-200 rounded"></div>
                    <div className="h-4 w-48 bg-gray-200 rounded"></div>
                    <div className="h-4 w-32 bg-gray-200 rounded"></div>
                  </div>
                  <div className="flex gap-2 mt-3">
                    <div className="h-8 w-20 bg-gray-200 rounded"></div>
                    <div className="h-8 w-24 bg-gray-200 rounded"></div>
                  </div>
                </div>
              ))}
            </div>
          ) : listError ? (
            <div className="p-8 text-center text-gray-500">
              <div className="flex flex-col items-center gap-2">
                <p>{listError}</p>
                <button
                  onClick={() => getRequests(page, filter, requestNumber)}
                  className="text-black hover:text-gray-600 font-medium"
                >
                  Try again
                </button>
              </div>
            </div>
          ) : dropRequests.length === 0 ? (
            <div className="p-8 text-center text-gray-500">
              <p>No pickup requests found</p>
            </div>
          ) : (
            <ul className="divide-y divide-gray-100">
              {dropRequests.map((pickup, index) => (
                <li
                  key={index}
                  className="p-4 hover:bg-gray-50"
                >
                  <Link to={"/dashboard/pickup/" + pickup.id} className="block">
                    <div className="flex items-start justify-between mb-1">
                      <div>
                        <div className="text-sm text-gray-500">
                          {format(new Date(pickup.createdAt), "dd MMM. yyyy")}
                        </div>
                        <div className="font-semibold">#{pickup.number}</div>
                      </div>

                      <span
                        className={`px-2 py-1 text-xs font-medium rounded-full ${pickup.status === "cancelled"
                            ? "bg-gray-100 text-gray-800"
                            : pickup.status === "scheduled"
                              ? "bg-yellow-100 text-yellow-800"
                              : pickup.status === "pending"
                                ? "bg-red-100 text-red-800"
                                : "bg-green-100 text-green-800"
                          }`}
                      >
                        {pickup.status}
                      </span>
                    </div>

                    <div className="text-sm font-medium text-gray-900">
                      {pickup.customer.firstName} {pickup.customer.lastName}
                    </div>

                    <div className="text-sm text-gray-500 mt-1">
                      {pickup.pairsEstimate} pairs • {pickup.logisticsOption}
                      {pickup.discountCode && ` • ${pickup.discountCode}`}
                    </div>

                    <div className="text-sm text-gray-500 mt-1">
                      Preferred Date: {format(new Date(pickup.preferredDate), "dd MMM. yyyy")}
                    </div>
                  </Link>

                  <div className="mt-3 flex flex-wrap gap-2">
                    {pickup.status !== "collected" && pickup.status !== "cancelled" && (
                      <Link
                        to={"/dashboard/pickup/" + pickup.id + "/edit"}
                        className="inline-flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
                      >
                        <PencilIcon className="h-4 w-4" />
                        <span>Edit</span>
                      </Link>
                    )}
                    {pickup.status === "pending" && (
                      <button
                        onClick={() => {
                          setConfirmPickup(pickup);
                          setOpenConfirmModal(true);
                        }}
                        className="inline-flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
                      >
                        <ClockIcon className="h-4 w-4" />
                        <span>Schedule</span>
                      </button>
                    )}
                    {pickup.status === "scheduled" && (
                      <Link
                        to={"/dashboard/order/create/" + pickup.id}
                        className="inline-flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
                      >
                        <ShoppingCartIcon className="h-4 w-4" />
                        <span>Create Order</span>
                      </Link>
                    )}
                    {pickup.status !== "collected" && pickup.status !== "cancelled" && (
                      <button
                        onClick={() => {
                          setConfirmPickup(pickup);
                          setOpenConfirmModal(true);
                          setCancelRequest(true);
                        }}
                        className="inline-flex items-center gap-2 px-3 py-1.5 text-sm text-red-600 hover:text-red-900 hover:bg-red-50 rounded-lg"
                      >
                        <XMarkIcon className="h-4 w-4" />
                        <span>Cancel</span>
                      </button>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {!listLoading && dropRequests.length > 0 && (
        <div className="flex justify-center">
          <Pagination
            currentPage={parseInt(page) || 1}
            totalItems={requestsCount}
            itemsPerPage={20}
            onPageChange={(newPage) => navigatePages(newPage, filter, requestNumber)}
          />
        </div>
      )}

      {openConfirmModal && (
        <ConfirmPickupModalComponent
          request={confirmPickup}
          getRequests={getRequests}
          cancelRequest={cancelRequest}
          setCancelRequest={setCancelRequest}
          setConfirm={setOpenConfirmModal}
        />
      )}
    </div>
  );
}
export default PickupRequests;

import React, { useState, useEffect } from "react";
import {
  PlusIcon,
  ArrowPathIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import OrderService from "../../services/order.service";
import ActionMenuComponent from "../../components/ActionMenuComponent";
import BulkActionMenuComponent from "../../components/BulkActionMenuComponent";
import moment from "moment";
import SelectLocationComponent from "../../components/SelectLocationComponent";
import Pagination from "../../components/Pagination";
import FilterDropdown from "../../components/FilterDropdown";

function Orders() {
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [filter, setFilter] = useState(searchParams.get("filter") || "");
  const [serviceLocationId, setServiceLocationId] = useState(
    searchParams.get("serviceLocationId") || ""
  );
  const [ordersList, setOrdersList] = useState([]);
  const [ordersCount, setordersCount] = useState();
  const [locationsList, setLocationsList] = useState([]);

  const [orderNumber, setOrderNumber] = useState("");
  const [delCount, setDelCount] = useState(0);
  const [listLoading, setListLoading] = useState(false);
  const [listError, setListError] = useState();
  const navigate = useNavigate();

  const formatDate = (date) => {
    return moment(date).calendar(null, {
      sameDay: "[Today at] HH:mm", // Today
      nextDay: "[Tomorrow]", // Tomorrow
      nextWeek: "dddd", // Next week
      lastDay: "[Yesterday at] HH:mm", // Yesterday
      lastWeek: "dddd [at] HH:mm", // Last week (Monday, Tuesday, etc.)
      sameElse: "D MMM. 'YY [at] HH:mm", // Older dates (22 Feb at 11:43)
    });
  };

  const getLocations = () => {
    OrderService.getLocations()
      .then((resp) => {
        setLocationsList(resp.locations);
      })
      .catch((err) => { });
  };

  const checkDueOrder = (order) => {
    const returnDate = moment(order.return.date);
    const isDueSoon = returnDate.diff(moment(), "days") < 2;
    const isActiveOrder =
      order.status !== "cancelled" && order.status !== "delivered";

    if (isDueSoon && isActiveOrder) {
      const dueText =
        moment().diff(returnDate, "hours") < 24
          ? returnDate.calendar({
            sameDay: "[Today]",
            nextDay: "[Tomorrow]",
            nextWeek: "dddd",
            lastDay: "[Yesterday]",
            lastWeek: "[Last] dddd",
            sameElse: "MM/DD/YYYY",
          })
          : returnDate.fromNow();

      return <small className="text-red-500">Due {dueText}</small>;
    }

    return null;
  };

  const handleSelectOrder = (index) => {
    ordersList[index].isChecked = !ordersList[index].isChecked;
    setOrdersList(ordersList);
    setDelCount(ordersList.filter((order) => order.isChecked === true).length);
  };
  const navigatePages = (page, filter, order, serviceLocationId) => {
    // 👇️ navigate to /
    navigate(
      `/dashboard/orders?page=${page}&filter=${filter}&order=${order}&serviceLocationId=${serviceLocationId}`
    );
    setPage(page);
    setFilter(filter);
    setServiceLocationId(serviceLocationId);
    getOrders(page, filter, order, "", serviceLocationId);
  };
  const getOrders = (page, filter, order, customerId, serviceLocationId) => {
    setListLoading(true);
    setListError(false);
    OrderService.getOrders(
      filter,
      page || 1,
      order,
      customerId,
      serviceLocationId
    )
      .then((resp) => {
        setOrdersList(resp.orders);
        setordersCount(resp.count);
        setListLoading(false);
      })
      .catch((err) => {
        setListLoading(false);
        setListError("Something went wrong.");
        console.log(err);
      });
  };
  useEffect(() => {
    if (searchParams.get("order")) setOrderNumber(searchParams.get("order"));
    getOrders(
      searchParams.get("page"),
      searchParams.get("filter") || "",
      searchParams.get("order") || "",
      searchParams.get("customerId") || "",
      searchParams.get("serviceLocationId") || ""
    );
    getLocations();
  }, []);
  return (
    <div className="h-full">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row sm:items-center gap-4 mb-6">
        <h1 className="text-2xl font-semibold flex-1">Orders</h1>

        <div className="flex flex-wrap items-center gap-3">
          {/* Search Box */}
          <div className="relative w-full sm:w-auto min-w-[240px]">
            <input
              type="number"
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
              placeholder="Search with order number..."
              onChange={(e) => {
                setOrderNumber(e.target.value);
                navigatePages(1, "", e.target.value, serviceLocationId);
              }}
              defaultValue={orderNumber}
            />
            <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>

          {ordersList.filter((order) => order.isChecked === true).length > 0 && (
            <BulkActionMenuComponent
              getOrders={() => getOrders(page)}
              orders={ordersList.filter((order) => order.isChecked === true)}
            />
          )}

          <Link
            to={"/dashboard/order/create"}
            className="flex items-center gap-2 bg-black hover:bg-gray-800 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200"
          >
            <PlusIcon className="h-4 w-4" />
            <span>Create Order</span>
          </Link>
        </div>
      </div>

      {/* Filters */}
      <div className="flex flex-wrap gap-4 mb-6">
        <FilterDropdown
          label="Status"
          value={filter}
          options={[
            { value: "", label: "All" },
            { value: "confirmed", label: "Confirmed" },
            { value: "ready", label: "Ready" },
            { value: "picked_up", label: "Picked Up" },
            { value: "delivered", label: "Delivered" }
          ]}
          onChange={(value) => navigatePages(1, value, orderNumber, serviceLocationId)}
        />

        <FilterDropdown
          label="Location"
          value={serviceLocationId}
          options={[
            { value: "", label: "All Locations" },
            ...locationsList.map(location => ({
              value: location.id,
              label: location.name
            }))
          ]}
          onChange={(value) => navigatePages(1, filter, orderNumber, value)}
        />
      </div>

      {/* Table Section */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
        <div className="hidden md:block">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 text-xs font-medium text-gray-500 uppercase tracking-wider">
                <th className="p-3 w-12">
                  <div className="font-semibold text-left"></div>
                </th>
                <th className="p-3">
                  <div className="font-semibold text-left">Order</div>
                </th>
                <th className="p-3">
                  <div className="font-semibold text-left">Customer</div>
                </th>
                <th className="p-3">
                  <div className="font-semibold text-left">Service Location</div>
                </th>
                <th className="p-3">
                  <div className="font-semibold text-left">Drop</div>
                </th>
                <th className="p-3 max-md:hidden">
                  <div className="font-semibold text-left">Return</div>
                </th>
                <th className="p-3">
                  <div className="font-semibold text-left">Total</div>
                </th>
                <th className="p-3">
                  <div className="font-semibold text-left">Payment Status</div>
                </th>
                <th className="p-3">
                  <div className="font-semibold text-left">Order Status</div>
                </th>
                <th className="p-3">
                  <div className="font-semibold text-left">Action</div>
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-100">
              {listLoading ? (
                [...Array(5)].map((_, i) => (
                  <tr key={i}>
                    <td colSpan={10} className="p-3">
                      <div className="animate-pulse flex items-center space-x-4">
                        <div className="h-4 w-4 bg-gray-200 rounded"></div>
                        <div className="flex-1 space-y-4">
                          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : listError ? (
                <tr>
                  <td colSpan={10} className="py-8 text-center text-gray-500">
                    <div className="flex flex-col items-center gap-2">
                      <ExclamationCircleIcon className="h-5 w-5 text-gray-400" />
                      <p>{listError}</p>
                      <button
                        onClick={() => getOrders(page, filter, orderNumber, serviceLocationId)}
                        className="text-black hover:text-gray-600 font-medium"
                      >
                        Try again
                      </button>
                    </div>
                  </td>
                </tr>
              ) : ordersList.length === 0 ? (
                <tr>
                  <td colSpan={10} className="py-8 text-center text-gray-500">
                    <p>No orders found</p>
                  </td>
                </tr>
              ) : (
                ordersList.map((order, index) => (
                  <tr
                    key={index}
                    className={`h-6 hover:bg-teal-100 hover:bg-opacity-50 ${checkDueOrder(order) && "bg-red-100/25 hover:bg-red-200"
                      } ${order.isChecked && "bg-gray-200"} ${(order.status === "delivered" ||
                        order.status === "cancelled") &&
                      "bg-gray-100"
                      } ${order.status === "cancelled" && "opacity-50"} cursor-pointer`}
                    onClick={() => navigate(`/dashboard/order/${order.id}`)}
                  >
                    <td className="p-2" onClick={(e) => e.stopPropagation()}>
                      {order.status === "draft" ? (
                        ""
                      ) : order.status === "cancelled" ? (
                        ""
                      ) : order.status === "delivered" ? (
                        ""
                      ) : (
                        <input
                          type="checkbox"
                          className="w-5 h-5 cursor-pointer"
                          onChange={() => handleSelectOrder(index)}
                          value="id-1"
                        />
                      )}
                    </td>
                    <td className="p-2 flex flex-col">
                      <small>{formatDate(order.createdAt)}</small>
                      <span className="text-left font-bold">
                        #{order.number}
                      </span>
                    </td>
                    <td className="p-2">
                      <div className="font-medium text-gray-800">
                        {order.customer.firstName} {order.customer.lastName}
                      </div>
                      <small>{checkDueOrder(order)}</small>
                    </td>
                    <td className="p-2" onClick={(e) => e.stopPropagation()}>
                      {(!order.serviceLocation && order.status !== "cancelled") ? (
                        <SelectLocationComponent
                          order={order}
                          orders={ordersList}
                          setOrders={setOrdersList}
                          locations={locationsList}
                        />
                      ) : order.serviceLocation?.name}</td>
                    <td className="p-2">
                      <p className="text-left">
                        {moment(order.drop.date).format("D MMM. 'YY")}
                      </p>
                      <small className="capitalize">{order.drop.type}</small>
                    </td>
                    <td className="p-2 max-md:hidden">
                      <p className="text-left">
                        {moment(order.return.date).format("D MMM. 'YY")}
                      </p>
                      <small className="capitalize">{order.return.type}</small>
                    </td>
                    <td className="p-2">
                      <div className="font-medium text-gray-800">
                        &#8358;
                        {(order.amount / 100).toLocaleString()}
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="text-left">
                        <span
                          className={`text-xs font-semibold py-1 px-2 uppercase rounded  ${!order.paymentMethod
                            ? "text-yellow-800 bg-yellow-200"
                            : "text-green-600 bg-green-200"
                            } uppercase`}
                        >
                          {!order.paymentMethod ? "Unpaid" : "Paid"}
                        </span>
                      </div>
                    </td>
                    <td className="p-2">
                      <div className="text-left">
                        <span
                          className={`text-xs font-semibold py-1 px-2 uppercase rounded  ${order.status === "draft"
                            ? "text-gray-600 bg-gray-200"
                            : order.status === "cancelled"
                              ? "text-red-600 bg-red-200"
                              : order.status === "delivered"
                                ? "text-gray-600 bg-gray-200"
                                : "text-green-600 bg-green-200"
                            } uppercase`}
                        >
                          {order.status.replace("_", " ")}
                        </span>
                      </div>
                    </td>
                    <td className="p-2" onClick={(e) => e.stopPropagation()}>
                      {order.status !== "cancelled" && (
                        <ActionMenuComponent
                          order={order}
                          orders={ordersList}
                          setOrders={setOrdersList}
                        />
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="md:hidden">
          {listLoading ? (
            <div className="p-4">
              <div className="animate-pulse space-y-4">
                {[...Array(3)].map((_, i) => (
                  <div key={i} className="flex items-center space-x-4">
                    <div className="h-4 w-4 bg-gray-200 rounded"></div>
                    <div className="flex-1 space-y-3">
                      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : listError ? (
            <div className="p-8 text-center text-gray-500">
              <div className="flex flex-col items-center gap-2">
                <ExclamationCircleIcon className="h-5 w-5 text-gray-400" />
                <p>{listError}</p>
                <button
                  onClick={() => getOrders(page, filter, orderNumber, serviceLocationId)}
                  className="text-black hover:text-gray-600 font-medium"
                >
                  Try again
                </button>
              </div>
            </div>
          ) : (
            <ul className="divide-y divide-gray-100">
              {ordersList.map((order, index) => {
                return (
                  <li
                    className={`p-4 hover:bg-gray-50 ${checkDueOrder(order) && "bg-red-100/25 hover:bg-red-200"
                      } ${order.isChecked && "bg-gray-100"} ${(order.status === "delivered" || order.status === "cancelled") &&
                      "bg-gray-50"
                      } ${order.status === "cancelled" && "opacity-50"}`}
                    key={index}
                  >
                    <div className="flex items-start gap-3">
                      <input
                        type="checkbox"
                        className="mt-1 w-4 h-4 rounded border-gray-300 text-black focus:ring-gray-500"
                        disabled={
                          order.status === "draft" ||
                          order.status === "cancelled" ||
                          order.status === "delivered"
                        }
                        checked={order.isChecked || false}
                        onChange={() => handleSelectOrder(index)}
                      />

                      <div className="flex-1">
                        <Link to={"/dashboard/order/" + order.id} className="block">
                          <div className="flex items-start justify-between mb-1">
                            <div>
                              <div className="text-sm text-gray-500">
                                {formatDate(order.createdAt)}
                              </div>
                              <div className="font-semibold">#{order.number}</div>
                            </div>

                            <div className="flex flex-wrap gap-1.5 items-start">
                              <span className={`px-2 py-1 text-xs font-medium rounded-full ${!order.paymentMethod
                                ? "text-yellow-800 bg-yellow-100"
                                : "text-green-800 bg-green-100"
                                }`}>
                                {!order.paymentMethod ? "Unpaid" : "Paid"}
                              </span>

                              <span className={`px-2 py-1 text-xs font-medium rounded-full ${order.status === "draft"
                                ? "text-gray-800 bg-gray-100"
                                : order.status === "cancelled"
                                  ? "text-red-800 bg-red-100"
                                  : order.status === "delivered"
                                    ? "text-gray-800 bg-gray-100"
                                    : "text-green-800 bg-green-100"
                                }`}>
                                {order.status.replace("_", " ")}
                              </span>
                            </div>
                          </div>

                          <div className="text-sm font-medium text-gray-900">
                            {order.customer.firstName} {order.customer.lastName}
                          </div>

                          <div className="text-sm font-medium mt-1">
                            ₦{(order.amount / 100).toLocaleString()}
                          </div>

                          <div className="text-sm text-gray-500 mt-1">
                            {format(new Date(order.drop.date), "dd MMM. yyyy")} - {format(new Date(order.return.date), "dd MMM. yyyy")}
                            {order.serviceLocation && ` • ${order.serviceLocation.name}`}
                          </div>

                          {checkDueOrder(order) && (
                            <div className="mt-2 text-sm text-red-600 font-medium">
                              {checkDueOrder(order)}
                            </div>
                          )}
                        </Link>

                        <div className="mt-3 flex flex-wrap gap-3">
                          {order.status !== "cancelled" && (
                            <ActionMenuComponent
                              order={order}
                              orders={ordersList}
                              setOrders={setOrdersList}
                            />
                          )}

                          {(!order.serviceLocation && order.status !== "cancelled") && (
                            <SelectLocationComponent
                              order={order}
                              orders={ordersList}
                              setOrders={setOrdersList}
                              locations={locationsList}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        <div className="border-t border-gray-100 px-6 py-4 flex flex-col sm:flex-row sm:items-center justify-between gap-4">
          <small className="text-gray-500 text-center sm:text-left">
            Showing {ordersList.length} of {ordersCount} results
          </small>
          <Pagination
            currentPage={parseInt(page) || 1}
            totalItems={ordersCount}
            itemsPerPage={20}
            onPageChange={(newPage) => navigatePages(newPage, filter, orderNumber, serviceLocationId)}
          />
        </div>
      </div>
    </div>
  );
}
export default Orders;

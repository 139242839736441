import userAPI from "./index.service";

class CustomerService {
  // Addresss
  getCustomerAddresses(id) {
    return userAPI.get("customers/" + id + "/addresses").then((response) => {
      return response.data;
    });
  }
  newCustomerAddress(id, data) {
    return userAPI.post("customers/" + id + "/addresses", data).then((response) => {
      return response.data;
    });
  }

  getCustomerSegments(segment, params) {
    return userAPI.get("customer-segments/" + segment, { params }).then((response) => {
      return response.data;
    });
  }


  exportCustomerSegments(segment, params) {
    return userAPI.get("customer-segments/" + segment + "/export", { params }).then((response) => {
      return response.data;
    });
  }
  // Customer
  getCustomers(q, perPage, page) {
    return userAPI
      .get("customers", { params: { q, perPage, page } })
      .then((response) => {
        return response.data;
      });
  }
  getCustomer(id) {
    return userAPI.get("customers/" + id).then((response) => {
      return response.data;
    });
  }
  newCustomer(data) {
    return userAPI.post("customers", data).then((response) => {
      return response.data;
    });
  }
  updateCustomer(data) {
    return userAPI.post(`${"customers/" + data.id}`, data).then((response) => {
      return response.data;
    });
  }
}

export default new CustomerService();

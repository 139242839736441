import {
  ArrowPathIcon,
  CalendarDaysIcon,
  InformationCircleIcon,
  XMarkIcon,
  PhoneIcon,
} from "@heroicons/react/24/solid";
import React, { useState } from "react";
import validator from "validator";
import customerService from "../services/customer.service";

const AddCustomerModal = (props) => {
  const { open, close, getCustomers } = props;
  const [form, setForm] = useState({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleAddCustomer = () => {
    setLoading(true);
    setError();
    if (!form.firstName || !form.phone) {
      setError("Required fields cannot be empty");
      setLoading(false);
      return;
    }
    if (validator.isEmpty(form.firstName) || validator.isEmpty(form.phone)) {
      setError("Required fields cannot be empty");
      setLoading(false);
      return;
    }
    if (form.email) {
      if (!validator.isEmail(form.email)) {
        setError("This is not a valid email");
        setLoading(false);
        return;
      }
    }
    let request = {
      ...form,
      phone:
        parseInt(form.phone).toString().slice(0, 3) === "234"
          ? `234${parseInt(form.phone.substring(3))}`
          : `234${parseInt(form.phone)}`,
    };
    customerService
      .newCustomer(request)
      .then((resp) => {
        setForm({});
        setLoading(false);

        close();
        getCustomers();
      })
      .catch((err) => {
        setError(err.response.data);
        setLoading(false);
      });
  };
  return (
    <div
      id="modal"
      className={`py-12 bg-gray-700 bg-opacity-40 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0 ${
        !open && "hidden"
      }`}
    >
      <div
        role="alert"
        className={`container mx-auto w-11/12 md:w-2/3 max-w-lg`}
      >
        <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
          <div className="text-gray-800 text-2xl font-bold mb-3">
            New Customer
          </div>
          <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">
            Enter Customer Details
          </h1>
          {error && (
            <div
              className="flex items-center space-x-2 mb-3 bg-red-500 bg-opacity-80 text-white text-sm font-bold px-4 py-3"
              role="alert"
            >
              <InformationCircleIcon className="h-4 w-4" />
              <p>{error}</p>
            </div>
          )}
          <div className="flex space-x-4">
            <div className="flex-1">
              <label
                htmlFor="firstName"
                className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Firstname
              </label>
              <input
                name="firstName"
                onChange={(e) => handleChange(e)}
                className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                placeholder="James"
              />
            </div>
            <div className="flex-1">
              <label
                htmlFor="lastName"
                className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Lastname (optional)
              </label>
              <input
                name="lastName"
                onChange={(e) => handleChange(e)}
                className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                placeholder="Nwafor"
              />
            </div>
          </div>
          <label
            htmlFor="phone"
            className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
          >
            Phone Number
          </label>
          <div className="relative mb-5 mt-2">
            <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
              <PhoneIcon className="h-5 w-5" />
            </div>
            <input
              name="phone"
              onChange={(e) => handleChange(e)}
              className="text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-16 text-sm border-gray-300 rounded border"
              placeholder="XXXXXXXXXXX"
            />
          </div>
          <label
            htmlFor="email"
            className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
          >
            Email
          </label>
          <input
            name="email"
            onChange={(e) => handleChange(e)}
            className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
            placeholder="somebody@service.com"
          />
          <label
            htmlFor="dob"
            className="text-gray-800 text-sm font-bold leading-tight tracking-normal"
          >
            Date Of Birth (optional)
          </label>
          <div className="relative mb-5 mt-2">
            <div className="absolute right-0 text-gray-600 flex items-center pr-3 h-full cursor-pointer">
              <CalendarDaysIcon className="h-5 w-5" />
            </div>
            <input
              name="dob"
              onChange={(e) => handleChange(e)}
              className="text-gray-600 focus:outline-none focus:border focus:border-gray-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
              placeholder="DD/MM/YYYY"
            />
          </div>

          <div className="flex items-center justify-start w-full">
            <button
              onClick={handleAddCustomer}
              disabled={loading}
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700 transition duration-150 ease-in-out hover:bg-gray-800 bg-black rounded text-white px-8 py-2 text-sm"
            >
              {loading ? (
                <ArrowPathIcon className="h-6 w-6 mx-2 animate-spin" />
              ) : (
                "Submit"
              )}
            </button>
            <button
              className="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 ml-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
              onClick={close}
            >
              Cancel
            </button>
          </div>
          <button
            onClick={close}
            className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddCustomerModal;

import userAPI from "./index.service";
import axios from "axios";

class OrderService {
  getServices() {
    return userAPI.get("services").then((response) => {
      return response.data;
    });
  }
  getOrders(status, page, order, customerId, serviceLocationId) {
    return userAPI
      .get("orders", {
        params: {
          status,
          page,
          order,
          perPage: 20,
          customerId,
          serviceLocationId,
        },
      })
      .then((response) => {
        return response.data;
      });
  }
  getLocations() {
    return userAPI.get("servicelocations").then((response) => {
      return response.data;
    });
  }

  addOrderToLocation(data, orderId) {
    return userAPI.post(`orders/${orderId}/assign-location`, data).then((response) => {
      return response.data;
    });
  }
  deleteLocation(locationId) {
    return userAPI.delete("servicelocations/" + locationId).then((response) => {
      return response.data;
    });
  }
  addLocation(data) {
    return userAPI.post(`servicelocations`, data).then((response) => {
      return response.data;
    });
  }
  getOrder(order) {
    return userAPI.get("orders/" + order).then((response) => {
      return response.data;
    });
  }
  getDueOrders() {
    return userAPI.get("orders/due").then((response) => {
      return response.data;
    });
  }
  getOrderActivity(order) {
    return userAPI.get("orders/" + order + "/activity").then((response) => {
      return response.data;
    });
  }
  getOrderItems(orderId) {
    return userAPI.get("orders/" + orderId + "/items").then((response) => {
      return response.data;
    });
  }
  updateOrderItemStatus(orderId, itemId, status) {
    return userAPI
      .put("orders/" + orderId + "/items/" + itemId + "/status", status)
      .then((response) => {
        return response.data;
      });
  }
  deleteOrderItem(orderId, itemId) {
    return userAPI
      .delete("orders/" + orderId + "/items/" + itemId)
      .then((response) => {
        return response.data;
      });
  }
  createOrder(data, confirm) {
    return userAPI
      .post(`orders${confirm ? "?confirmed=1" : ""}`, data)
      .then((response) => {
        return response.data;
      });
  }
  createOrderItem(data, orderId) {
    return userAPI
      .post("orders/" + orderId + "/items", data)
      .then((response) => {
        return response.data;
      });
  }
  updateOrder(data) {
    return userAPI.post(`${"orders/" + data.id}`, data).then((response) => {
      return response.data;
    });
  }
  payOrder(data, id) {
    return userAPI.put(`${"orders/" + id + "/pay"}`, data).then((response) => {
      return response.data;
    });
  }
  bulkUpdate(data) {
    return userAPI.put("orders/bulk", data).then((response) => {
      return response.data;
    });
  }
  getCustomerSegments(params) {
    return userAPI.get("orders", { params }).then((response) => {
      return { customers: [{ name: "John", totalOrders: 10, totalSpent: 1000, avgOrderValue: 100, lastOrderDate: "2021-01-01", firstOrderDate: "2021-01-01" }] };
    });
  }
}

export default new OrderService();

import {
  ArrowPathIcon,
  ClipboardIcon,
  PencilIcon, ShareIcon
} from "@heroicons/react/24/solid";
import { format } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import ConfirmModalComponent from "../../components/ConfirmModal";
import DropdownComponent from "../../components/DropdownComponent";
import OrderService from "../../services/order.service";
import OrderItem from "./OrderItem";

function ViewOrder() {
  const notify = useOutletContext();
  const [servicesList, setServicesList] = useState([]);
  const [customer, setCustomer] = useState();
  const [orderLoading, setOrderLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [orderActivity, setOrderActivity] = useState();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmOrder, setConfirmOrder] = useState({});
  let { order } = useParams();
  const getInvoice = () => {
    let invoice =
      `Hi ${orderDetails.customer.firstName},

Your bill for order #${orderDetails.number} is as follows:

` +
      Object.entries(orderDetails.services)
        .map(
          ([key, value], index) =>
            `${index + 1}. ${getServiceDetail(key)[0]?.name} x${value} - ₦${(
              (getServiceDetail(key)[0]?.cost * value) /
              100
            ).toLocaleString()}
`
        )
        .join("") +
      `
Pickup - ₦${(orderDetails.drop.fee / 100).toLocaleString()}
Delivery - ₦${(orderDetails.return.fee / 100).toLocaleString()}
*Total - ₦${(orderDetails.amount / 100).toLocaleString()}*

Please make payment to:
Account Number: 9880324115
Bank Name: Paystack-Titan
Account Name: Sneaklinmulti/Laundry`;
    return invoice;
  };

  const getOrder = () => {
    setOrderLoading(true);
    OrderService.getOrder(order)
      .then((resp) => {
        setOrderDetails(resp);
        setCustomer(resp.customer);

        setOrderLoading(false);
      })
      .catch((err) => {
        console.log(err);
        notify("success", "Error", "Theres an error");
        setOrderLoading(false);
      });
  };

  const getOrderActivity = () => {
    setOrderLoading(true);
    OrderService.getOrderActivity(order)
      .then((resp) => {
        setOrderActivity(resp.activity);

        setOrderLoading(false);
      })
      .catch((err) => {
        console.log(err);
        notify("success", "Error", "Theres an error");
        setOrderLoading(false);
      });
  };

  useEffect(() => {
    getOrder();
    getOrderActivity();
  }, []);
  const getServiceDetail = (code) => {
    let service = servicesList.filter((f) => f.code === code);
    return service;
  };
  const getServices = () => {
    OrderService.getServices()
      .then((resp) => {
        setServicesList(resp.services);
      })
      .catch((err) => { });
  };
  useEffect(() => {
    getServices();
  }, []);
  return (
    <>
      {orderDetails ? (
        <div className="h-full">
          {/* Header */}
          <div className="flex flex-col sm:flex-row sm:items-start gap-4 mb-6">
            <BackButton />
            <div className="flex-1">
              <h1 className="text-2xl font-semibold">Order #{orderDetails.number}</h1>
              <div className="text-gray-500">
                {moment(orderDetails.createdAt).isBefore(
                  Date.now() - 3 * 24 * 3600 * 1000
                )
                  ? moment(orderDetails.createdAt).format(
                    "dddd, Do MMM. YYYY, h:mm:ss a"
                  )
                  : moment(orderDetails.createdAt).fromNow()}
              </div>
            </div>
            <div>
              <Link
                to={"/dashboard/order/" + order + "/edit"}
                className="inline-flex items-center gap-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors duration-200"
              >
                <PencilIcon className="h-4 w-4" />
                <span>Edit Order</span>
              </Link>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row gap-6 mb-6">
            {/* Main Content */}
            <div className="flex-1 space-y-6">
              {/* Services Section */}
              {orderLoading ? (
                <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                  <div className="space-y-4">
                    <div className="animate-pulse space-y-2">
                      <div className="h-6 w-24 bg-gray-200 rounded"></div>
                      <div className="h-4 w-48 bg-gray-200 rounded"></div>
                    </div>
                    <div className="animate-pulse space-y-4 pt-4">
                      {[...Array(3)].map((_, i) => (
                        <div key={i} className="flex justify-between items-center">
                          <div className="h-4 w-32 bg-gray-200 rounded"></div>
                          <div className="h-4 w-16 bg-gray-200 rounded"></div>
                          <div className="h-4 w-24 bg-gray-200 rounded"></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                  <div className="space-y-4">
                    <div>
                      <h2 className="text-lg font-medium">Services</h2>
                      <div className="text-gray-500">
                        {orderDetails.drop.type === "walk-in"
                          ? "Walk In "
                          : "Pickup "}
                        - {format(new Date(orderDetails.drop.date), "dd MMM. yy")} |{" "}
                        {orderDetails.return.type === "walk-in"
                          ? "Walk In "
                          : "Delivery "}
                        - {format(new Date(orderDetails.return.date), "dd MMM. yy")}
                      </div>
                    </div>

                    <table className="w-full">
                      <tbody className="divide-y divide-gray-100">
                        {Object.entries(orderDetails.services).map(
                          ([key, value]) => (
                            <tr key={key}>
                              <td className="py-3">
                                <span className="font-medium text-gray-900">
                                  {getServiceDetail(key)[0]?.name}
                                </span>
                              </td>
                              <td className="py-3 text-center">{value}</td>
                              <td className="py-3 text-right font-medium">
                                ₦
                                {(
                                  (getServiceDetail(key)[0]?.cost * value) /
                                  100
                                ).toLocaleString()}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              <OrderItem />
              {/* Collection Details */}
              {orderLoading ? (
                <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                  <div className="animate-pulse space-y-4">
                    <div className="h-6 w-32 bg-gray-200 rounded"></div>
                    <div className="space-y-3">
                      {[...Array(2)].map((_, i) => (
                        <div key={i} className="space-y-2">
                          <div className="h-4 w-24 bg-gray-200 rounded"></div>
                          <div className="h-4 w-40 bg-gray-200 rounded"></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                  <h2 className="text-lg font-medium mb-4">Collection Details</h2>
                  <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
                    {/* Drop Off */}
                    <div>
                      <h3 className="text-sm font-medium text-gray-500 mb-2">Drop Off</h3>
                      <div className="space-y-2">
                        <div className="flex items-center gap-2">
                          <span className="text-gray-600">Type:</span>
                          <span className="font-medium capitalize">{orderDetails.drop.type}</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="text-gray-600">Date:</span>
                          <span className="font-medium">{moment(orderDetails.drop.date).format("DD MMMM YYYY")}</span>
                        </div>
                        {orderDetails.drop.type === "delivery" && (
                          <div className="mt-3 p-3 bg-gray-50 rounded-lg">
                            <div className="font-medium">{orderDetails.drop.address.firstName} {orderDetails.drop.address.lastName}</div>
                            <div className="text-sm text-gray-600 mt-1">
                              {orderDetails.drop.address.address1}, {orderDetails.drop.address.address2}
                              {orderDetails.drop.address.city}
                              +{orderDetails.drop.address.phone}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Return */}
                    <div>
                      <h3 className="text-sm font-medium text-gray-500 mb-2">Return</h3>
                      <div className="space-y-2">
                        <div className="flex items-center gap-2">
                          <span className="text-gray-600">Type:</span>
                          <span className="font-medium capitalize">{orderDetails.return.type}</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="text-gray-600">Date:</span>
                          <span className="font-medium">{moment(orderDetails.return.date).format("DD MMMM YYYY")}</span>
                        </div>
                        {orderDetails.return.type === "delivery" && (
                          <div className="mt-3 p-3 bg-gray-50 rounded-lg">
                            <div className="font-medium">{orderDetails.return.address.firstName} {orderDetails.return.address.lastName}</div>
                            <div className="text-sm text-gray-600 mt-1">
                              {orderDetails.return.address.address1}, {orderDetails.return.address.address2}
                              {orderDetails.return.address.city}
                              +{orderDetails.return.address.phone}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Sidebar */}
            <div className="w-full xl:w-[400px] space-y-6">
              {/* Customer Section */}
              {orderLoading ? (
                <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                  <div className="animate-pulse space-y-3">
                    <div className="h-6 w-48 bg-gray-200 rounded"></div>
                    <div className="h-4 w-32 bg-gray-200 rounded"></div>
                    <div className="h-4 w-40 bg-gray-200 rounded"></div>
                  </div>
                </div>
              ) : (
                <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                  {customer ? (
                    <div className="space-y-1">
                      <h2 className="text-lg font-medium">
                        <Link
                          to={"/dashboard/orders?customerId=" + customer.id}
                          className="hover:text-gray-600"
                        >
                          {customer.firstName} {customer.lastName}
                        </Link>
                      </h2>
                      <div className="text-gray-600">{customer.phone}</div>
                      <div className="text-sm text-gray-500">{customer.email}</div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center h-24">
                      <ArrowPathIcon className="h-5 w-5 text-gray-400 animate-spin" />
                    </div>
                  )}
                </div>
              )}


              {/* Payment Section */}
              {orderLoading ? (
                <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                  <div className="animate-pulse space-y-4">
                    <div className="flex items-center gap-3">
                      <div className="h-6 w-20 bg-gray-200 rounded"></div>
                      <div className="h-6 w-16 bg-gray-200 rounded-full"></div>
                    </div>
                    <div className="space-y-3">
                      {[...Array(5)].map((_, i) => (
                        <div key={i} className="flex justify-between">
                          <div className="h-4 w-24 bg-gray-200 rounded"></div>
                          <div className="h-4 w-20 bg-gray-200 rounded"></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                  <div className="flex items-center gap-3 mb-4">
                    <h2 className="text-lg font-medium">
                      {orderDetails.paymentMethod === "" ? "Unpaid" : "Paid"}
                    </h2>
                    <span className={`px-2 py-1 text-xs font-medium rounded-full ${orderDetails.paymentMethod === "" && "text-yellow-800 bg-yellow-100"
                      } ${orderDetails.paymentMethod === "cash" && "text-green-800 bg-green-100"}
                    ${orderDetails.paymentMethod === "card" && "text-green-800 bg-green-100"}
                    ${orderDetails.paymentMethod === "transfer" && "text-green-800 bg-green-100"}`}>
                      {orderDetails.paymentMethod === "" && "Later"}
                      {orderDetails.paymentMethod === "cash" && "Cash"}
                      {orderDetails.paymentMethod === "card" && "Card"}
                      {orderDetails.paymentMethod === "transfer" && "Transfer"}
                    </span>
                  </div>

                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-gray-600">Subtotal</span>
                      <span className="font-medium">
                        ₦
                        {Object.entries(orderDetails.services)
                          .map(
                            ([key, value]) =>
                              (getServiceDetail(key)[0]?.cost * value) / 100
                          )
                          .reduce((accumulator, object) => {
                            return accumulator + object;
                          }, 0)
                          .toLocaleString()}
                      </span>
                    </div>

                    {orderDetails.drop.type === "delivery" && (
                      <div className="flex justify-between">
                        <span className="text-gray-600">Pickup</span>
                        <span className="font-medium">
                          ₦
                          {(orderDetails.drop.fee / 100 || 0).toLocaleString()}
                        </span>
                      </div>
                    )}

                    {orderDetails.return.type === "delivery" && (
                      <div className="flex justify-between">
                        <span className="text-gray-600">Delivery</span>
                        <span className="font-medium">
                          ₦
                          {(orderDetails.return.fee / 100 || 0).toLocaleString()}
                        </span>
                      </div>
                    )}

                    <div className="flex justify-between">
                      <span className="text-gray-600">Discount</span>
                      <span>{orderDetails.discount || 0}%</span>
                    </div>

                    <div className="flex justify-between">
                      <span className="text-gray-600">Tax</span>
                      <span>0%</span>
                    </div>

                    <div className="flex justify-between pt-3 border-t border-gray-100">
                      <span className="font-medium">Total</span>
                      <span className="font-medium">
                        ₦{(orderDetails.amount / 100).toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {/* Order Updates */}
              {orderLoading ? (
                <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                  <div className="animate-pulse space-y-4">
                    <div className="h-6 w-32 bg-gray-200 rounded"></div>
                    <div className="space-y-4">
                      {[...Array(4)].map((_, i) => (
                        <div key={i} className="pl-4 border-l-2 border-gray-200 space-y-2">
                          <div className="h-4 w-40 bg-gray-200 rounded"></div>
                          <div className="h-3 w-32 bg-gray-200 rounded"></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                  <h2 className="text-lg font-medium mb-4">Order Updates</h2>
                  <div className="space-y-4">
                    {orderActivity?.map((activity, key) => (
                      <div
                        key={key}
                        className={`pl-4 border-l-2 ${key === 0
                            ? "border-green-600 text-green-700 font-medium"
                            : "border-gray-200"
                          }`}
                      >
                        <div className="capitalize">
                          {activity.action.replace("_", " ")}
                        </div>
                        <div className="text-sm text-gray-500">
                          {moment(activity.timestamp).isBefore(
                            Date.now() - 3 * 24 * 3600 * 1000
                          )
                            ? moment(activity.timestamp).format(
                              "dddd, Do MMM. YYYY, h:mm:ss a"
                            )
                            : moment(activity.timestamp).fromNow()}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Action Buttons */}
          {!orderDetails.paymentMethod && (
            <div className="flex flex-wrap items-center justify-end gap-3">
              <button
                onClick={() => {
                  navigator.clipboard
                    .writeText(getInvoice())
                    .then(() => alert("Bill copied to clipboard."));
                }}
                className="inline-flex items-center gap-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors duration-200"
              >
                <ClipboardIcon className="h-4 w-4" />
                <span>Copy Bill</span>
              </button>
              <button
                onClick={() => {
                  window.open(
                    "https://wa.me/" +
                    orderDetails.customer.phone +
                    "?text=" +
                    getInvoice(),
                    "_blank"
                  );
                }}
                className="inline-flex items-center gap-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors duration-200"
              >
                <ShareIcon className="h-4 w-4" />
                <span>Send Bill</span>
              </button>
              <DropdownComponent
                order={orderDetails}
                className="relative bg-black text-white"
                setConfirm={setOpenConfirmModal}
                setConfirmOrder={setConfirmOrder}
              />
            </div>
          )}

          {openConfirmModal && (
            <ConfirmModalComponent
              order={confirmOrder}
              getOrders={getOrder}
              setConfirm={setOpenConfirmModal}
            />
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center h-[calc(100vh-8rem)]">
          <ArrowPathIcon className="h-10 w-10 text-gray-400 animate-spin" />
        </div>
      )}
    </>
  );
}
export default ViewOrder;

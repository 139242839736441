import React, { forwardRef, useState, useEffect } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowPathIcon,
  PencilIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/solid";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddressPicker from "../../components/AddressPicker";
import PickupService from "../../services/pickup.service";
import BackButton from "../../components/BackButton";

function ViewPickup() {
  let { requestId } = useParams();
  const [requestData, setRequestData] = useState({
    createdAt: new Date(),
    preferredDate: new Date(),
  });
  const [loading, setLoading] = useState(false);

  const getRequest = () => {
    setLoading(true);
    PickupService.getRequest(requestId)
      .then((resp) => {
        setRequestData(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getRequest();
  }, []);
  return (
    <div className="h-full">
      <div className="flex flex-col sm:flex-row sm:items-center gap-4 mb-6">
        <div className="flex items-center gap-3">
          <BackButton />
          {loading ? (
            <div className="animate-pulse space-y-2">
              <div className="h-8 w-48 bg-gray-200 rounded"></div>
              <div className="h-4 w-32 bg-gray-200 rounded"></div>
            </div>
          ) : (
            <div>
              <h1 className="text-2xl font-semibold">Request #{requestData.number}</h1>
              <div className="text-sm text-gray-500">
                {format(new Date(requestData.createdAt), "dd MMMM yyyy 'at' HH:mm")}
              </div>
            </div>
          )}
        </div>
        
        <div className="sm:ml-auto flex flex-wrap gap-2">
          {!loading && (
            <>
              {requestData.status !== "collected" && requestData.status !== "cancelled" && (
                <Link
                  to={"/dashboard/pickup/" + requestId + "/edit"}
                  className="inline-flex items-center gap-2 px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors duration-200"
                >
                  <PencilIcon className="h-4 w-4" />
                  <span>Edit Request</span>
                </Link>
              )}
              {requestData.status === "collected" && (
                <Link
                  to={"/dashboard/order/create/" + requestData.id}
                  className="inline-flex items-center gap-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors duration-200"
                >
                  <ShoppingCartIcon className="h-4 w-4" />
                  <span>Create Order</span>
                </Link>
              )}
            </>
          )}
        </div>
      </div>

      <div className="max-w-4xl mx-auto">
        {loading ? (
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 divide-y divide-gray-100">
            {/* Customer Information Loading State */}
            <div className="p-6">
              <div className="animate-pulse space-y-4">
                <div className="h-6 w-48 bg-gray-200 rounded"></div>
                <div className="space-y-2">
                  <div className="h-5 w-40 bg-gray-200 rounded"></div>
                  <div className="h-4 w-32 bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>

            {/* Pickup Details Loading State */}
            <div className="p-6">
              <div className="animate-pulse space-y-6">
                <div className="h-6 w-40 bg-gray-200 rounded"></div>
                <div className="space-y-4">
                  <div className="space-y-2">
                    <div className="h-5 w-48 bg-gray-200 rounded"></div>
                    <div className="h-4 w-64 bg-gray-200 rounded"></div>
                    <div className="h-4 w-32 bg-gray-200 rounded"></div>
                    <div className="h-4 w-36 bg-gray-200 rounded"></div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {[...Array(4)].map((_, i) => (
                      <div key={i} className="space-y-2">
                        <div className="h-4 w-32 bg-gray-200 rounded"></div>
                        <div className="h-5 w-24 bg-gray-200 rounded"></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : requestData.customer && (
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 divide-y divide-gray-100">
            {/* Customer Information */}
            <div className="p-6">
              <h2 className="text-lg font-semibold mb-4">Customer Information</h2>
              <div className="space-y-2">
                <div className="font-medium text-gray-900">
                  {requestData.customer.firstName} {requestData.customer.lastName}
                </div>
                <div className="text-gray-600">{requestData.customer.phone}</div>
              </div>
            </div>

            {/* Pickup Details */}
            <div className="p-6">
              <h2 className="text-lg font-semibold mb-4">Pickup Details</h2>
              
              {requestData.address ? (
                <div className="space-y-4">
                  <div className="space-y-1">
                    <div className="font-medium text-gray-900">
                      {requestData.address.firstName} {requestData.address.lastName}
                    </div>
                    <div className="text-gray-600">
                      {requestData.address.address1}
                      {requestData.address.address2 && `, ${requestData.address.address2}`}
                    </div>
                    <div className="text-gray-600">{requestData.address.city}</div>
                    <div className="text-gray-600">+{requestData.address.phone}</div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div>
                      <div className="text-sm font-medium text-gray-500 mb-1">Preferred Date</div>
                      <div className="text-gray-900">
                        {format(new Date(requestData.preferredDate), "dd MMMM yyyy")}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm font-medium text-gray-500 mb-1">Number of Pairs</div>
                      <div className="text-gray-900">{requestData.pairsEstimate}</div>
                    </div>
                    <div>
                      <div className="text-sm font-medium text-gray-500 mb-1">Pickup Method</div>
                      <div className="text-gray-900 capitalize">{requestData.logisticsOption}</div>
                    </div>
                    <div>
                      <div className="text-sm font-medium text-gray-500 mb-1">Pickup Fee</div>
                      <div className="text-gray-900">₦{requestData.pickupFee?.toLocaleString()}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-gray-500">No address found</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ViewPickup;

import React from "react";

// ✅ Reusable Component for Order Stats
export const OrderStatCard = ({ title, value, prevValue, type }) => {
  const calculatePercentageDifference = (original, newNumber) => {
    if (original === 0) return newNumber === 0 ? 0 : 100;
    return ((newNumber - original) / original) * 100;
  };
  const percentageChange = calculatePercentageDifference(prevValue, value);
  return (
    <div className="w-full">
      <div className="relative flex flex-col min-w-0 break-words bg-white rounded-xl shadow-sm border border-gray-100 p-2">
        <div className="flex-auto px-4 py-2">
          <h5 className="text-blueGray-400 uppercase font-bold text-xs">
            {title}
          </h5>
          <div className="flex items-center gap-2">
            <p className="font-semibold text-xl text-blueGray-700">
              {type === "amount"
                ? `₦${(value / 100).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}`
                : value}{" "}
            </p>
            {prevValue !== undefined && (
              <span class={`inline-flex items-center rounded-md 
                ${!isFinite(percentageChange) ? "bg-gray-50 text-gray-700" : 
                   percentageChange >= 0 ? "bg-green-50 text-green-700" : "bg-red-50 text-red-700"} 
                px-2 py-0.5 text-[0.65rem] font-medium ring-1 ring-red-600/10 ring-inset`}>
                {!isFinite(percentageChange) ? '-' : `${Math.round(percentageChange)}%`}
              </span>
            )}
          </div>

          {/* <small className="text-gray-500 text-xs">
            {"vs previous period" + " ~ " + (type === "amount"
              ? `₦${(prevValue / 100).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}`
              : prevValue)}
          </small> */}
        </div>
      </div>
    </div >
  );
};
